/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import Dropzone from 'react-dropzone';
import CloudIcon from "../../../../assets/icons/cloudIcon";
import DotIcon from "../../../../assets/icons/dot";
import { BaseLoader } from "../../../../components/baseloader";
import { PostRequest } from "../../../../includes/functions";
import { DownloadCSV } from "../../../../includes/createCSVFileHook";
import { toast } from "react-toastify";

interface ImportPersonnelComponentprops {
  onClose: () => void;
  reload?: () => void;
}


export const ImportPersonnelComponent = (props: ImportPersonnelComponentprops) => {
  const [loading, setLoading] = useState<boolean>(false);
  const UploadCSVFile = (files: File[]) => {
    const file = files[0];  // Get the uploaded file
    if (!file) return;
    const reader = new FileReader();
    reader.onload = () => {
      var list:any[] = [];
      const text = reader.result as string;
      const rows = text.split('\n');  // Split text into rows
      const data = rows.map(row => row.split(','));  // Split each row into columns
      var all:string = "";
       data.forEach((a,i)=>{
        try {
          const allRow = a.join(",").replace(/[ ]/g,'');
          if(allRow.includes("companyEmail") && allRow.includes("companyName")){
            all = allRow;
          }else{
            if(i !== 0 && a[0])
            {
            list.push({companyEmail:a[0],companyName:String(a[2]).trim().replace(/[\r]/g,'')});
            }
          }
          
        } catch (error) {
          toast.error("Ooh! invalid file, please upload the downloaded template.")
        }
      })
      if(list.length === 0)
      {
        return ;
      }
      setLoading(true);
      PostRequest(
      'business/invite/bulk',
      {businesses:list},
      true,
      false
    ).then((res) => {
      setLoading(false);
      if (res.status) {
        const reloadEvent = new CustomEvent('reloadEmployeeTable', {});
        window.dispatchEvent(reloadEvent);
        props.onClose();
      }
    });
    };

    reader.onerror = () => {
     toast.error("Ooh! File can't be read.")
    };
    reader.readAsText(file); 
  };
  const handleDownloadTemple = ()=>{
    DownloadCSV({
      data:[
        {[`companyEmail`]:"companyEmail",[`companyName`]:"companyName"},
        {[`companyEmail`]:"hr@companymail.com",[`companyName`]:"best holdings"}
      ],
      fileName:"Employee Import Template"
    })
  }
  return (
    <div className="modal" tabIndex={-1}>
      <div className="modal-dialog">
        <div
          className="modal-content"
          style={{ borderRadius: 20, marginTop: 100 }}
        >
          <div className="modal-header">
            <h5 className="modal-title">Upload CSV</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                if (!loading) {
                  props.onClose();
                }
              }}
            ></button>
          </div>
          <div className="modal-body" style={{position:"relative"}}>
            <p>
              Upload a CSV to quickly import employee details and create users
            </p>
            <Dropzone
              onDrop={(acceptedFiles) => {
                UploadCSVFile(acceptedFiles);
              }}
              accept={{
                'text/csv': ['.csv'],
              }}
            >
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <div
                    {...getRootProps()}
                    className="card text-center"
                    style={{
                      backgroundColor:
                        isDragActive || loading ? '#6efeb266' : '#DEE0E3',
                      cursor: 'pointer',
                      height:200
                    }}
                  >
                    {loading ? (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ height: 80 }}
                      >
                        <div>
                          <BaseLoader />
                          <small className="dx mx-3">
                            Please wait while we upload file.
                          </small>
                        </div>
                      </div>
                    ) : (
                      <div className='text-center' style={{height:200,width:"100%"}}>
                        <center className='pt-5'>
                          <CloudIcon />
                        </center>
                        <small className="dx">
                          Drag your file here to upload
                        </small>
                        <input {...getInputProps()} accept=".csv" 
                        />
                      </div>
                    )}
                  </div>
                );
              }}
            </Dropzone>
            <div className='text-center d-flex justify-content-center align-items-center g-2' style={{position:"absolute",bottom:"7%",width:"100%"}}>
                          <span className='pe-1'>Browse files </span>
                          <span className="py-5">
                            <DotIcon />
                          </span>
                          <button 
                          className='btn p-0 m-0 ps-1'
                          onClick={handleDownloadTemple}
                          >
                           Download sample CSV
                          </button>
                        </div>
            {/* <Formik
onSubmit={(values)=>{

}}
validationSchema={schema}
initialValues={{
    firstname:"",
    lastname:""
}}
>
{({handleSubmit,handleChange,values})=><div className='' >
      
      <table className="table table-responsive table-bordered">
        <tr >
            <td>
        <BaseInput 
        name='firstname'
        type='text'
        placeholder='First name'
        onValueChange={handleChange("firstname")}  
         value={values.firstname}
        required={true}
        />
        </td>
        <td>
         <BaseInput 
        name='lastname'
        type='text'
        placeholder='Last name'
        onValueChange={handleChange("lastname")} 
         value={values.lastname}
        required={true}
        />
        </td>
        <td>
          <TrashIcon />  
        </td>
        </tr>
        </table>
        <BaseButton 
        onClick={handleSubmit}
        loading={loading}
        >Save</BaseButton>
        </div>
       }
     </Formik> */}
          </div>
        </div>
      </div>
    </div>
  );
}