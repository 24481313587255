/* eslint-disable react-hooks/exhaustive-deps */
import React, { FormEvent, RefObject, useEffect, useRef, useState } from 'react';
import './../style.css';
import './styles/style.css';
import { GetRequest, PostRequest, ShowError, uploadImageToCloud } from '../../../includes/functions';
import { BaseButton } from '../../../components/buttons';
import { Formik, FormikProps, FormikValues } from 'formik';
import * as y from 'yup';
import BaseInput from '../../../components/baseInput';
import { UserProps } from '../../../includes/types';
import { CONSTANTS, passwordRules } from '../../../includes/constant';
// import {ImageIcon} from '../../../assets/icons/imageIcon';
import ImageIcon from '../../../assets/icons/imageIcon';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { BaseLoader } from '../../../components/baseloader';

export default function SettingsSection() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [logoUploaded, setLogoUploaded] = useState(false);
  const [tab, setTab] = useState<string>('profile');
  const [fileName, setFileName] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [user, setUser] = useState<UserProps>({
    firstName:"",
    lastName:"",
    email:"",
    oldPassword:"",
    newPassword:""
  });
  const [logo, setLogo] = useState<string | null>(null);

  const GetUserInfo = () => {
    GetRequest('user', {})
      .then((response) => {
        if (response.status) {
          setUser(response.data);
          setLogo(response.data.image);
        }
      })
  };
  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
        setFileName(file.name);
        const reader = new FileReader();
        reader.onload = (e) => {
          setLogo(reader.result as string);
          setLogoUploaded(true);
          uploadImageToCloud(file).then((res)=>{
        if(!res.status)
        {
          setLogoUploaded(false);
          return ShowError(res.message)
        }
          const data = {
            image: res.data
          };
          PostRequest('put:user', data, true).then((res)=>{
            setLogoUploaded(false);
          })
        })
        };
        reader.readAsDataURL(file);
      } else {
        setLogoUploaded(false);
        ShowError('Please upload a valid image file.');
      }
    }
  useEffect(() => {
    GetUserInfo();
  }, []);

const handleProfileUpdate = (e:FormEvent)=>{
  e.preventDefault();
  setLoading2(true);
  const data:any = {}
  const userObj:any = user
  Object.keys(userObj).forEach((a:any,i)=>{
    if(userObj[a] !== "" && a !== "email"  && a !== "isVerified")
    {
      data[a] = userObj[a]
    }
  })
    PostRequest('put:user',data, true).then((response) => {
     setLoading2(false);
    })
}
const handlePasswordChange= (e:FormEvent)=>{
  e.preventDefault();
  setLoading(true);
    PostRequest('put:auth/change-password', {
      oldPassword:user.oldPassword,
      password:user.newPassword
    }, true)
      .then((response) => {
        setLoading(false);
        if(response.status)
        {
          navigate("/");
        }
      })
}
  return (
    <div className="main-scrollable p-5 pt-0">
      <div className="heading mb-3">
        <b className="fCap">My Settings</b>
      </div>
      <div className='d-flex align-items-center justify-content-start tabContainer' >
        <nav className="nav pl-c">
        <span
          onClick={() => {
            setTab('profile');
            setSearchText('');
          }}
          className={`nav-link active btn p-2 mb-2 ${
            tab === 'profile' ? 'fw-bold selected-tab' : 'fw-normal'
          }`}
          aria-current="page"
        >
          Profile
        </span>
        {/* <span
          onClick={() => {
            setSearchText('');
            setTab('notification');
          }}
          className={`nav-link btn p-2 mb-2 ${
            tab === 'notification' ? 'fw-bold selected-tab' : 'fw-normal'
          }`}
        >
            Notification{' '}
        </span> */}
      </nav>
      </div>

      {tab === 'profile' ? (
          <div className="mt-4 w={40} h={40}">
        <div className="row setting-profile">
                <div className="col-1">
                  <center>
                    <div className='position-relative d-flex justify-content-center align-items-center' style={{width:80,height:80}}>
                    {logo ? (
                      <img src={logo} alt="User Logo" className="avatar" />
                    ) : (
                      <div className="avatar d-flex align-items-center justify-content-center position-relative">
                        {/* Initials with the upload icon */}
                        <span className="fs-2 fw-bold orange-text">
                          {[String(user.firstName), String(user.lastName)]
                            .map((a) => a[0])
                            .join('')}
                        </span>
                        <span
                          className="upload-icon position-absolute"
                          style={{
                            padding: '7px',
                            borderRadius: '19px',
                            border: '1px solid #FFFFFF',
                            right: '-5px',
                            bottom: '4px',
                            backgroundColor:
                              'var(--Colour-Gray-Gray-95, #EFF0F0)',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            document.getElementById('logo-upload')?.click()
                          }
                        >
                          <ImageIcon />
                        </span>
                      </div>
                    )}
                    {logoUploaded &&<span style={{position:"absolute"}}>
                      <BaseLoader />
                    </span>}
                    </div>
                    <div className='text-center'>
                    <b>
                      {user.firstName}<br/>{user.lastName}
                    </b>
                    </div>
                    <div>
                      <input
                        id="logo-upload"
                        type="file"
                        accept="image/*"
                        onChange={handleLogoChange}
                        className="d-none"
                      />
                    </div>
                  </center>
                </div>
                <div className="col-6">
              <form
              onSubmit={handleProfileUpdate}
              >
                  <div>
                    <small>First name</small>
                  </div>
                  <BaseInput
                    name="firstName"
                    type="text"
                    placeholder="First name"
                    max={20}
                    onValueChange={(d) =>
                      setUser({...user,firstName:d.target.value})
                    }
                    value={user.firstName}
                  />
                  <div>
                    <small>Last name</small>
                  </div>
                  <BaseInput
                    name="lastName"
                    type="text"
                    placeholder="Last name"
                    max={20}
                    onValueChange={(d) =>
                      setUser({...user,lastName:d.target.value})
                    }
                    value={user.lastName}
                  />
                  <div>
                    <small>Email address</small>
                  </div>
                  <BaseInput
                    disabled
                    name="email"
                    type="email"
                    placeholder="Email address"
                    max={50}
                    onValueChange={() => { }}
                    value={user.email}
                    required
                  />
                  <div className="d-flex justify-content-end">
                    <BaseButton 
                    type='submit'
                    loading={loading2}>
                      Save changes
                    </BaseButton>
                  </div>
                  </form>
                  <form 
                  onSubmit={handlePasswordChange}
                  >
                  <div className='mb-3'>
                    <b style={{fontSize:18}}>Change password</b>
                  </div>
                  <div>
                    <small>Current Password</small>
                  </div>
                  <BaseInput
                    name="password"
                    type="password"
                    placeholder="Enter new password"
                    max={20}
                    onValueChange={(d) =>
                      setUser({...user,oldPassword:d.target.value})
                    }
                    value={user.oldPassword}
                    required
          
                  />
                   <div>
                    <small>New Password</small>
                  </div>
                   <BaseInput
                    name="password"
                    type="password"
                    placeholder="Enter new password"
                    max={20}
                    onValueChange={(d) =>
                      setUser({...user,newPassword:d.target.value})
                    }
                    value={user.newPassword}
                    required
                  />
                  <div className="row">
                    <div className="col-12 mb-3"></div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <BaseButton 
                    type='submit'
                    loading={loading}>
                      Save password
                    </BaseButton>
                  </div>
                  </form>
                </div>
                <div className="col-2"></div>
            </div>
        </div>
      ) : null}
    </div>
  );
}

const NotificationComponent: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  interface NotificationSetting {
    label: string;
    type: keyof NotificationSettings;
  }

  interface NotificationSettings {
    newBusiness: string;
    newAdmin: string;
    employeeSignup: string;
    newUserCheckIn: string;
    newUserCheckOut: string;
  }

  const [notificationSettings, setNotificationSettings] =
    useState<NotificationSettings>({
      newBusiness: 'None',
      newAdmin: 'None',
      employeeSignup: 'None',
      newUserCheckIn: 'None',
      newUserCheckOut: 'None',
    });

    const saveNotificationSettings = async (settings: NotificationSettings) => {
      try {
        const response = await PostRequest('patch:admin/notification-settings', settings, true);
        if (response.status) {
          toast.success('Notification settings saved successfully.', {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        console.error('Error saving notification settings:', error);
        toast.error('Failed to save notification settings.', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    };

    const handleNotificationChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      type: keyof NotificationSettings
    ) => {
      const updatedSettings = {
        ...notificationSettings,
        [type]: event.target.value,
      };
  
      setNotificationSettings(updatedSettings);
      saveNotificationSettings(updatedSettings);
    };

  const generalNotifications: NotificationSetting[] = [
    { label: 'When a new business joins', type: 'newBusiness' },
    { label: 'When a new admin joins', type: 'newAdmin' },
    { label: 'When an employee signs up', type: 'employeeSignup' },
  ];

  const activityNotifications: NotificationSetting[] = [
    { label: 'When new user checks in', type: 'newUserCheckIn' },
    { label: 'When new user checks out', type: 'newUserCheckOut' },
  ];

  const NotificationOption = ({ label, type }: NotificationSetting) => (
    <>
    <div className="notification-option mb-4 row align-items-center">
      <div className="col-8">
        <div className="label-text" style={{ marginBottom: '16px' }}>{label}</div>
      </div>
      <div className="col-4">
       <ToggleSwitch 
       onValue={(d)=>{

       }}
       value='None'
       />
      </div>
    </div>
    <hr />
    </>
  );

  return (
    <div className='mt-4 gap-9'>
      <div className="body-content d-flex">
        <div className='col-3' style={{ marginRight: '16px' }}>
          <h5>General Notifications</h5>
          <p className="body-text">
            Select when to be notified when the following changes occur
          </p>
        </div>
        <div className='col-8'>
          {generalNotifications.map((notification) => (
            <NotificationOption key={notification.type} 
            
            {...notification} />
          ))}
        </div>
      </div>

      <div className="body-content d-flex">
        <div className='col-3' style={{ marginRight: '16px' }}>
          <h5>Activity Notifications</h5>
          <p className="body-text">
            Select when to be notified when the following changes occur
          </p>
        </div>
        <div className='col-8'>
          {activityNotifications.map((notification) => (
            <NotificationOption key={notification.type} {...notification} />
          ))}
        </div>
      </div>
    </div>
  );
};
interface ToggleSwitchProp {
  onValue:(d:"None"|"In-App")=>void;
  value:"None"|"In-App";
}
const ToggleSwitch = (prop:ToggleSwitchProp)=>{
const [selectedBtn,setSelectedBtn] = useState<number>(0);
useEffect(()=>{
  prop.onValue(selectedBtn?"None":"In-App");
},[selectedBtn])
  return  <div className="btn-group-x row" >
  <div 
  onClick={()=>setSelectedBtn(0)}
  className={`inner-btn col-6 d-flex align-items-center justify-content-center ${selectedBtn === 0?"inner-btn-active":""}`} >
  None
  </div>
  <div 
  onClick={()=>setSelectedBtn(1)}
  className={`inner-btn col-6 d-flex align-items-center justify-content-center ${selectedBtn !== 0?"inner-btn-active":""}`} >
    In-App
  </div>
  </div>
}