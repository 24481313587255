/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { RefObject, useEffect, useRef, useState } from 'react'
import './../style.css';
import { AddBusinessComponent } from '../components/addPersonnel';
import { ImportPersonnelComponent } from '../components/importPersonnel';
import { Pagination } from '../../../components/pagination';
import { GetRequest, PostRequest } from '../../../includes/functions';
import {  HistoryProps } from '../../../includes/types';
import moment from 'moment';
import { DownloadIcon } from '../../../assets/icons/DownloadBtn';
import { BaseLoader } from '../../../components/baseloader';
import { HistorySearchBar } from '../components/HistorySearchBar';
import { ChartBoard } from '../components/chartSection';
import { TabProps } from './dashboard';
import { toast } from 'react-toastify';
import { DownloadCSV } from '../../../includes/createCSVFileHook';
import { SearchBar } from '../components/searchBar';
import { CloseIcon } from '../../../assets/icons/CloseIcon';
import { Searchicon } from '../icon';

export default function TreepzHistorySection() {
  const [processing, setProcessing] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null);
  const [showAddBusiness, setshowAddBusiness] = useState<boolean>(false)
  const [employeeDetails, setEmployeeDetails] = useState<HistoryProps | null>(null)
  const [filterString, setFilterString] = useState<string>("");
  const [showImportPersonnel, setShowImportPersonnel] = useState<boolean>(false)
  const [historyList, setHistoryList] = useState<HistoryProps[]>([]);
  const [startDate, setStartDate] = useState<string>(moment().subtract(3, "M").toISOString());
  const [endDate, setEndDate] = useState<string>(moment().toISOString());
  const [pageSize, setPageSize] = useState<number>(50);
  const [totalEmployees, setTotalEmployees] = useState<number>(0);
  const [totalCheckIn, setTotalCheckIn] = useState<number>(0);
  const [totalCheckOut, setTotalCheckOut] = useState<number>(0);
  const [allCompaniesCount, setAllCompaniesCount] = useState<number>(0);

  const AllCompanies = (page: number) => {
    GetRequest(
      `businesses?items=${pageSize}&pageNumber=${page}&startDate=${startDate}&endDate=${endDate}`,
      {}, false).then((res) => {
      console.log(res);
      if (res.status) {
        setAllCompaniesCount(res.data.businesses.length);
        var numberOfEmployees: any[] = [];
        res.data.businesses.forEach((a: any, i: number) => {
          numberOfEmployees = [...numberOfEmployees, ...a.employees]
        })
        setTotalEmployees(numberOfEmployees.length);
        console.log(numberOfEmployees);
      }
    })
  }
  const AllCheckInCheckOut = () => {
    GetRequest("trip/check-in", {}, false).then((res) => {
      if (res.status) {
        setTotalCheckIn(res.data?.totalCheckIns);
      }
    })
    GetRequest("trip/check-out", {}, false).then((res) => {
      if (res.status) {
        setTotalCheckOut(res.data.totalCheckOuts);
      }
    })
  }
  
  const handleExport = () => {
    DownloadCSV({ data: historyList, fileName: "history_list" });
  }; 
  
  const GetRecentTrips = async () => {
    setLoading(true);
    try {
      const res = await GetRequest('trips?itemsPerPage=10&pageNumber=1', {}, false);
      console.log("API Response:", res);
  
      if (res.status && res.data && Array.isArray(res.data.trips)) {
        console.log("API Response Trips:", res.data.trips);
        setHistoryList(res.data.trips);
      } else {
        setHistoryList([]);
      }
    } catch (error) {
      console.error("Failed to fetch employees' trip history:", error);
      setError("Failed to fetch employees' trip history.");
    } finally {
      setLoading(false);
    }
  };

  
  const DeleteUser = (id: string) => {
    PostRequest("delete:admin/user", {
      id: id
    }).then((res) => {
      if (res.status) {
        setHistoryList(historyList.filter((a, i) => a.employeeId !== id));
      }
    })
  }
  const printedSection = useRef() as RefObject<HTMLDivElement>;
  useEffect(() => {
    AllCheckInCheckOut();
    AllCompanies(1);
    GetRecentTrips();
  }, [])
  const PrintInfo = () => {
    if (printedSection.current) {
      var openWindow = window.open("", "title", "attributes");
      openWindow?.document.write(printedSection.current?.innerHTML);
      openWindow?.document.close();
      openWindow?.focus();
      openWindow?.print();
      openWindow?.close();
    }
  }
  

  const tabs: TabProps[] = [
    { class: "light-gray-card", title: "Total Businesses", amount: allCompaniesCount },
    { class: "light-yellow-card", title: "Total Employees", amount: totalEmployees },
    { class: "pink-card", title: "Total Check-ins", amount: totalCheckIn },
    { class: "gray-card", title: "Total Check-outs", amount: totalCheckOut }
  ];

  const AllItems: HistoryProps[] = historyList.filter((trips, i) => String(trips?.rider).toLowerCase()?.includes(String(filterString).toLowerCase()) || String(trips?.name).toLowerCase()?.includes(String(filterString).toLowerCase()) || String(trips?.employeeId).toLowerCase()?.includes(String(filterString).toLowerCase()) || String(trips?.email).toLowerCase()?.includes(String(filterString).toLowerCase()));
const [searchString,setSearchString] = useState<string>("")
  return <div style={{ position: "relative" }}>
    <div className='main-scrollable p-5 pt-0' >
      <div className="heading mb-3">
        <b className='fCap'>Treepz history</b></div>
      <div className="mb-3">
        <ChartBoard
          backgroundColor="white"
          tabs={tabs}
        />
      </div>
      <div className='mb-4 mt-4'>
        <div className='row' >
        <div className='col-6' >
        <b className=' '>Treepz history </b>
        </div>
        <div className='col-6' >
       <div className="searchbar-container align-items-center"  >
            <span className="searchbar-icon" >
                <Searchicon />
            </span>
            <input className="form-control"
            style={{height:45,paddingLeft:50}}
                placeholder="Search with employee ID, name..."
                value={filterString}
                onChange={(d) => {
                  setFilterString(d.target.value)
                }}
            />
            {searchString !== "" ? <span
                onClick={() => {
                  setFilterString("");
                }}
                className="close-btn">
                <CloseIcon
                    size={15}
                />
            </span> : null}
        </div>
        </div>
        </div>
        </div>
      <HistorySearchBar
        calenderPosition="right"
        onSearch={(d) => {
        }}
        page='history'
        onAddBusiness={() => setshowAddBusiness(true)}
        processing={processing}
        onExportPersonnel={() => {
          handleExport()
        }}

        onImportBusiness={() => setShowImportPersonnel(true)}
        showFilter={true}
        onFilterValue={({ startDate, endDate }) => {
          setStartDate(startDate)
          setEndDate(endDate)
        }}
      />

      <table className="table table-responsive">
        <thead>
          <tr >
            {/* <th scope="col">
              <input type="checkbox" />
            </th> */}
            <th scope="col" >S. No.</th>
            <th scope="col">Company Name</th>
            <th scope="col">Employee ID</th>
            <th scope="col">Employee Name</th>
            <th scope="col">Date</th>
            <th scope="col">Check-In</th>
            <th scope="col">Check-Out</th>
            {/* <th scope="col">Vehicle Name</th> */}
            {/* <th scope="col">Pick-up Location</th> */}
            {/* <th scope="col">Vehicle Location</th> */}
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {loading && <tr >
            <td colSpan={10}>
              <BaseLoader /> <small className='fs-small'>Fetching...</small>
            </td>
          </tr>}
          {AllItems.map((trip, i) => 
          <tr key={i}>
            {/* <td >
              <input type="checkbox" />
            </td> */}
            <td>{(i + 1) > 9?(i + 1):"0"+(i + 1)}</td>
            {/* <td></td> */}
            {/* <td></td> */}
            <td>{trip.business}</td>
            <td>{trip.employeeId}</td>
            <td>{trip.rider}</td>
            <td>{moment(trip.createdAt).format("Do, MMM YYYY").replace("Invalid date","N/A")}</td>
            <td>{moment(trip.checkInTime).format("hh:mm A").replace("Invalid date","N/A")}</td>
            <td>{moment(trip.checkOutTime).format("hh:mm A").replace("Invalid date","N/A")}</td>
            {/* <td>{trip.vehicleName}</td> */}
            {/* <td>{trip.vehicleNumber}</td> */}
            {/* <td>{trip.vehicleLocation}</td> */}
            <td style={{ width: 50 }}>
              {/* <Menu
                onValue={(value) => {
                  if (value.action === "delete") {
                    DeleteUser(String(trip?.id))
                  }
                  if (value.action === "view") {
                    setEmployeeDetails(trip)
                  }
                }}
                type='history'
              >
                <ThreeVerticalDotsIcon />
              </Menu> */}
            </td>
          </tr>)}
        </tbody>
      </table>
      <Pagination
        onFilterRow={(d) => {
          setPageSize(d);
        }}
        onPage={(d) => {
          GetRecentTrips();
        }}
      />
      {showAddBusiness && <AddBusinessComponent
        onClose={() => setshowAddBusiness(false)}
      />}
      {showImportPersonnel && <ImportPersonnelComponent
        onClose={() => setShowImportPersonnel(false)}
      />}
    </div>
    {employeeDetails && <div className='main-scrollable p-5 pt-3' style={{ position: "absolute", top: 0, left: 0, background: "white", height: "100%", width: "100%" }} >
      <div style={{ width: 200, paddingTop: 20, paddingLeft: 10, height: 60, position: "fixed", top: 0, left: 360, backgroundColor: "white" }} >
        <b className='fCap'>Trip details</b>
      </div>
      <div className='row' >
        <div className='col-9' ref={printedSection}  >
          <div className='row pb-3' >
            <div className='col-4' >Employee ID</div>
            <div className='col-4' >{employeeDetails.id}</div>
          </div>

          <div className='row pb-3' >
            <div className='col-4' >Employee Name</div>
            <div className='col-4' >{employeeDetails.name}</div>
            <div className='col-4' ></div>
          </div>
          <div className='row pb-3' >
            <div className='col-4' >Email Address</div>
            <div className='col-4' >{employeeDetails.email}</div>
          </div>
          <div className='row pb-3' >
            <div className='col-4' >Check-In</div>
            <div className='col-4' >{moment(employeeDetails.checkInTime).format("hh:mm A").replace("Invalid date","N/A")}</div>
          </div>
          <div className='row pb-3' >
            <div className='col-4' >Check-Out</div>
            <div className='col-4' >{moment(employeeDetails.checkOutTime).format("hh:mm A").replace("Invalid date","N/A")} </div>
          </div>
          <div className='row pb-2' >
            <div className='col-4' >Vehicle Name</div>
            <div className='col-4' >{employeeDetails.vehicleName}</div>
          </div>
          <div className='row pb-2' >
            <div className='col-4' >Vehicle Number</div>
            <div className='col-4' >{employeeDetails.vehicleNumber}</div>
          </div>
          <div className='row pb-2' >
            <div className='col-4' >Vehicle Tag</div>
            <div className='col-4' >{employeeDetails.vehicleTag}</div>
          </div>
        </div>
        <div className='col-3' >
          <button className='gray-card btn'
            onClick={() => {
              PrintInfo()
            }}
          >
            <DownloadIcon />
            <span className='px-2 fw-bold fs-6' >Download receipt</span>
          </button>
        </div>
      </div>
    </div>}
  </div>
}
