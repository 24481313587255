/* eslint-disable react-hooks/exhaustive-deps */
import React, { RefObject, useEffect, useRef, useState } from 'react';
import ThreeVerticalDotsIcon from '../../../../assets/icons/threeDots';
import ResendIcon from '../../../../assets/icons/resendIcon';
import TrashIcon, { CancelIcon } from '../../../../assets/icons/trashIcon';
import { Pagination } from '../../../../components/pagination';
import UserIcon from '../../../../assets/icons/UserIcon';
import { ActionProps, BusinessProps } from '../../../../includes/types';
import { GetRequest, PostRequest } from '../../../../includes/functions';
import { BaseLoader } from '../../../../components/baseloader';
import moment from 'moment';
import { QrcodeIcon } from '../../../../assets/icons/QrCode';
import EditIcon from '../../../../assets/icons/editIcon';
import { DashboardIcon } from '../../icon';
import { ConfirmDialogComponent } from '../../components/confirmDialog';
import {
  emitEvent,
  subscribeEvent,
} from '../../../../includes/customEventHook';
import { CONSTANTS } from '../../../../includes/constant';
import { EyeOpen } from '../../../../assets/icons/eye';
import { useNavigate } from 'react-router-dom';
import { RoleProps } from '../rolesAndPermissions';

interface InvitationTableProps {
  searchText?: string;
}
const InvitationTable = (props: InvitationTableProps) => {
  const [loadingItem, setLoadingItem] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<BusinessProps | null>(null);
  const [pageSize, setPageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [listOfPendingInvitation, setListOfPendingInvitation] = useState<
    BusinessProps[]
  >([]);
  const [startDate, setStartDate] = useState<string>(
    moment().subtract(3, 'M').toISOString()
  );
  const [endDate, setEndDate] = useState<string>(moment().toISOString());
  const [fetching, setFetching] = useState(false);

  const GetInvitatedBusiness = (page: number) => {
    setFetching(true);
    GetRequest(
      `businesses?itemsPerPage=${pageSize}&pageNumber=${page}`,
      {},
      false
    ).then((res) => {
      console.log(res);
      setFetching(false);
      if (res.status) {
        const BusinessList: BusinessProps[] = res.data
          .businesses as BusinessProps[];
        setListOfPendingInvitation(
          BusinessList.filter((a, i) => a.status !== 'Active')
        );
        const data: any = BusinessList as any;
        emitEvent(CONSTANTS.Events.reloadAllBusiness, { detail: data });
      }
    });
  };

  const ResendInvitation = (v: BusinessProps) => {
    setLoadingItem(String(v._id));
    PostRequest(
      `put:business/invite/resend`,
      {
        companyEmail: v.email,
      },
      true
    ).then((res) => {
      setLoadingItem('');
    });
  };
  const CancelInvitation = (v: BusinessProps) => {
    setLoadingItem(String(v._id));
    PostRequest(`put:business/invite/cancel/${v._id}`, {}, true).then((res) => {
      setLoadingItem('');
      GetInvitatedBusiness(1);
    });
  };
  
  const DeleteInvitation = (v: BusinessProps) => {
    return ;
    setLoadingItem(String(v._id));
    PostRequest(`put:business/invite/delete/${v._id}`, {}, true).then((res) => {
      setLoadingItem('');
      GetInvitatedBusiness(1);
    });
  };
  useEffect(() => {
    GetInvitatedBusiness(1);
    window.addEventListener('reloadinvitedTable', (event: any) => {
      if (event.detail) {
        setEndDate(event.detail.endDate);
        setStartDate(event.detail.startDate);
        setTimeout(() => {
          GetInvitatedBusiness(currentPage);
        }, 1000);
      }
    });
  }, [currentPage]);

  const Alldata = listOfPendingInvitation.filter(
    (a, i) =>
      String(a.firstName)
        .toLowerCase()
        .includes(String(props.searchText).toLowerCase()) ||
      String(a.email)
        .toLowerCase()
        .includes(String(props.searchText).toLowerCase()) ||
      String(a.location)
        .toLowerCase()
        .includes(String(props.searchText).toLowerCase()) ||
        String(a.name)
          .toLowerCase()
          .includes(String(props.searchText).toLowerCase())
  );

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col th">S. No.</th>
            <th scope="col th">Company Name</th>
            <th scope="col th">Email Address</th>
            <th scope="col th">Status</th>
            <th scope="col th">Date of invitation</th>
            <th scope="col th"></th>
          </tr>
        </thead>
        <tbody>
          {fetching && (
            <tr>
              <td colSpan={10}>
                <BaseLoader /> <small className="fs-small">Fetching...</small>
              </td>
            </tr>
          )}
          {Alldata.map((a, i) => (
            <tr key={i}>
              <th scope="row">{i + 1}</th>
              <td>{a.name}</td>
              <td>{a.email}</td>
              <td><div className={String(a.status).toLowerCase().includes("cancelled")?"status-suspended":"status-pending"}>{String(a.status).toLowerCase().includes("cancelled")?"Cancelled":a.status}</div></td>
              <td>{moment(a.createdAt).format('Do, MMM YYYY').replace("Invalid date","N/A")}</td>
              <td style={{ width: 50 }}>
                <div
                  style={{ width: 50 }}
                  className="d-flex align-items-center justify-content-center"
                >
                  {loadingItem === a._id ? (
                    <BaseLoader />
                  ) : (
                    <Menu
                      onValue={(value) => {
                        if (value.action === 'resend') {
                          ResendInvitation(a);
                        }
                        if (value.action === 'cancel') {
                          setSelectedItem({...a,action:value.action});
                        }
                        if (value.action === 'delete') {
                          setSelectedItem({...a,action:value.action});
                        }
                      }}
                      type="data"
                    >
                      <ThreeVerticalDotsIcon />
                    </Menu>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        onFilterRow={(d) => {
          setPageSize(d);
        }}
        onPage={(d) => {
          GetInvitatedBusiness(d);
          setCurrentPage(d);
        }}
      />
      {selectedItem && (
        <ConfirmDialogComponent
        data={selectedItem}
          onClose={() => {
            setSelectedItem(null);
          }}
          confirm={() => {
            if(selectedItem.action === "cancel")
            {
            CancelInvitation(selectedItem);
            }else{
              DeleteInvitation(selectedItem); 
            }
            setSelectedItem(null);
          }}
        />
      )}
    </>
  );
};
export default InvitationTable;

export interface MenuProps {
  children: JSX.Element;
  onValue: (d: { value: string; action: ActionProps }) => void;
  type: 'history' | 'data' | 'new' | 'vehicle' | 'business' | 'employee' | 'role';
  list?: ItemProps[];
}
export interface ItemProps {
  title: string;
  value: string;
  icon?: JSX.Element;
  selected?:boolean;
}
export const Menu = (props: MenuProps) => {
  const [show, setShow] = useState<boolean>(false);
  const thisDropDown = useRef() as RefObject<HTMLDivElement>;
  
  useEffect(() => {
    thisDropDown.current?.addEventListener('mouseleave', () => {
      setShow(false);
    });
  }, []);

  return (
    <div className="dropdown-wrp">
      <button
        className="btn p-0 m-0"
        onClick={() => {
          setShow(!show);
        }}
      >
        {props.children}
      </button>
      <div className="card dropdown-cnt" ref={thisDropDown}>
        {show && (
          <ul>
            {props.type === 'role' ? (
              <>
                <li
                  onClick={() => {
                    props.onValue({
                      value: 'Change role',
                      action: 'change_role',
                    });
                    setShow(false);
                  }}
                >
                  <ResendIcon />
                  <span>Change role</span>
                </li>
                <li
                  onClick={() => {
                    props.onValue({
                      value: 'Remove admin',
                      action: 'remove_admin'
                    });
                    setShow(false);
                  }}
                >
                  <TrashIcon />
                  <span className='danger'>Remove admin</span>
                </li>
              </>
            ) : null}
 {props.type === 'data' ? (
              <>
                <li
                  onClick={() => {
                    props.onValue({
                      value: 'resend invitation',
                      action: 'resend',
                    });
                    setShow(false);
                  }}
                >
                  <ResendIcon />
                  <span>Resend invitation</span>
                </li>
                <li
                  onClick={() => {
                    props.onValue({
                      value: 'cancel invitation',
                      action: 'cancel',
                    });
                    setShow(false);
                  }}
                >
                  <CancelIcon />
                  <span className=''>Cancel invite</span>
                </li>
                <li
                  onClick={() => {
                    props.onValue({
                      value: 'delete invitation',
                      action: 'delete',
                    });
                    setShow(false);
                  }}
                >
                  <TrashIcon />
                  <span className='danger'>Delete invite</span>
                </li>
              </>
            ) : null}
            {props.type === 'history' ? (
              <>
                {[
                  { name: 'Company name', value: '' },
                  { name: 'Employee Id', value: '' },
                  { name: 'Pick-Up Location', value: '' },
                  { name: 'Vehicle location', value: '' },
                  { name: 'Vehicle tag', value: '' },
                ].map((a, i) => (
                  <li
                    key={i}
                    onClick={() => {
                      props.onValue({ value: 'view', action: 'view' });
                      setShow(false);
                    }}
                  >
                    <input type="checkbox" />
                    <span className="fs-12">{a.name}</span>
                  </li>
                ))}
              </>
            ) : null}

            {props.type === 'new' ? (
              <>
                <li
                  onClick={() => {
                    props.onValue({ value: 'import', action: 'import' });
                    setShow(false);
                  }}
                >
                  <ResendIcon />
                  <span>Import employee</span>
                </li>
                <li
                  onClick={() => {
                    props.onValue({ value: 'add', action: 'add' });
                    setShow(false);
                  }}
                  style={{width:168}}
                >
                  <UserIcon />
                  <span>Add single employee</span>
                </li>
              </>
            ) : null}

            {props.type === 'vehicle' ? (
              <>
                <li
                  onClick={() => {
                    props.onValue({ value: 'view', action: 'view' });
                  }}
                >
                  <span style={{ width: 30 }}>
                    <EditIcon />
                  </span>
                  <span className="small">View details</span>
                </li>
                <li
                  onClick={() => {
                    props.onValue({ value: 'edit', action: 'edit' });
                    // setShow(false);
                  }}
                >
                  <span style={{ width: 30 }}>
                    <EditIcon />
                  </span>
                  <span className="small">Edit details</span>
                </li>
                <li
                  onClick={() => {
                    props.onValue({ value: 'qrcode', action: 'qrcode' });
                    // setShow(false);
                  }}
                >
                  <span style={{ width: 30 }}>
                    <QrcodeIcon />
                  </span>
                  <span className="small">Generate QR</span>
                </li>
                <li
                  onClick={() => {
                    props.onValue({ value: 'delete', action: 'delete' });
                    setShow(false);
                  }}
                >
                  <span style={{ width: 30 }}>
                    <TrashIcon />
                  </span>
                  <span className="error small">Delete</span>
                </li>
              </>
            ) : null}

            {props.type === 'business' ? (
              <>
                <li
                  onClick={() => {
                    props.onValue({
                      value: 'user-setting',
                      action: 'user-setting',
                    });
                    setShow(false);
                  }}
                >
                  <span style={{ width: 30 }}>
                    <UserIcon />
                  </span>
                  <span className="small">My settings</span>
                </li>
                <li
                  onClick={() => {
                    props.onValue({
                      value: 'business-settings',
                      action: 'business-settings',
                    });
                    setShow(false);
                  }}
                >
                  <span style={{ width: 30 }}>
                    <DashboardIcon color="black" />
                  </span>
                  <span className="small">Business settings</span>
                </li>
              </>
            ) : null}

            {props.type === 'employee' ? (
              <>
                <li
                  onClick={() => {
                    props.onValue({ value: 'view', action: 'view' });
                    setShow(false);
                  }}
                >
                  <span style={{ width: 30 }}>
                    <EyeOpen />
                  </span>
                  <span className="small">View details</span>
                </li>
                <li
                  onClick={() => {
                    props.onValue({ value: 'edit', action: 'edit' });
                    setShow(false);
                  }}
                >
                  <span style={{ width: 30 }}>
                    <EditIcon />
                  </span>
                  <span className="small">Edit details</span>
                </li>
                <li
                  onClick={() => {
                    props.onValue({ value: 'delete', action: 'delete' });
                    setShow(false);
                  }}
                >
                  <span style={{ width: 30 }}>
                    <TrashIcon />
                  </span>
                  <span className="error small">Delete</span>
                </li>
              </>
            ) : null}
          </ul>
        )}
      </div>
    </div>
  );
};
