import React from 'react';
import { BusinessProps } from './types';
import { ShowError, ShowSuccess } from './functions';

export const DownloadCSV = (props: {
  data: any[] | BusinessProps[];
  fileName: string;
}) => {
  if(props.data.length !== 0)
    {
  const convertToCSV = (data: any[]) => {
    let str = '';
    for (let i = 0; i < data.length; i++) {
      let line = '';
      for (let index in data[i]) {
        if (line !== '') line += ',';
        line += data[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };
  const csvData = new Blob([convertToCSV(props.data)], { type: 'text/csv' });
  const csvURL = URL.createObjectURL(csvData);
  const link = document.createElement('a');
  link.href = csvURL;
  link.download = `${props.fileName}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  ShowSuccess('Download was successful.');
}else{
  ShowError("No available data to download.");
}
};
