/* eslint-disable no-useless-escape */

import { toast } from 'react-toastify';
import { CONSTANTS } from './constant';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
export interface APIResponse {
  status: boolean;
  data?: any;
  message: string;
  statusCode?: string;
}
export const PostRequest = (
  uri: string,
  data: any,
  success?: boolean,
  center?: boolean,
  fileType: 'json' | 'upload' = 'json'
) => {
  return new Promise<APIResponse>((resolve) => {
    const formdata = new FormData();
    Object.keys(data).forEach((ob, i) => {
      formdata.append(ob, data[ob]);
    });
    var method = 'post';
    if (String(uri).includes(':')) {
      method = String(uri).split(':')[0];
      uri = String(uri).split(':')[1];
    }
    const token = localStorage.getItem('token');
    const RequestHeaders: any = {
      'Content-Type':
        fileType === 'json' ? 'application/json' : 'multipart/form-data',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    };
    const options: AxiosRequestConfig = {
      headers: RequestHeaders,
      method: String(method).toLowerCase(),
      data: fileType === 'json' ? data : formdata,
      url: `${CONSTANTS.BaseURL}${uri}`,
    };
    console.log('RequestHeaders:', options);
    axios(options)
      .then(({ data }: AxiosResponse) => {
        // alert(JSON.stringify(data));
        if (data.status) {
          if (success) {
            toast.success(data.message, {
              position: center
                ? toast.POSITION.TOP_CENTER
                : toast.POSITION.TOP_RIGHT,
            });
          }
          try {
            if (data.data.token) {
              localStorage.setItem('token', data.data.token);
            }
          } catch (error) {}
          resolve(data);
        } else {
          toast.error(data.message, {
            position: center
              ? toast.POSITION.TOP_CENTER
              : toast.POSITION.TOP_RIGHT,
          });
          //  if(data.message.includes("malformed"))
          //  {
          //   localStorage.clear()
          //   window.location.href = "./";
          //  }
          resolve(data);
        }
      })
      .catch((data) => {
        console.log('error.response.data:', data);
        if (success) {
          toast.error(
            data?.response?.data ? data?.response?.data.message : data.message,
            {
              position: center
                ? toast.POSITION.TOP_CENTER
                : toast.POSITION.TOP_RIGHT,
            }
          );
        }
        resolve({
          status: false,
          message: data.message,
          data: {},
        });
      });
  });
};
export const GetRequest = (uri: string, data: any, success?: boolean) => {
  return new Promise<APIResponse>((resolve) => {
    var params: string[] = [];
    Object.keys(data).forEach((ob, i) => {
      params.push(`${ob}=${data[ob]}`);
    });
    const token = localStorage.getItem('token');
    const RequestHeaders = {
      'Access-Control-Allow-Origin': '*',
       Authorization: `Bearer ${token}`,
       };
    var options: AxiosRequestConfig = {
      headers: RequestHeaders,
      responseType: uri === 'vehicle/export' ? 'blob' : 'json',
    };
    console.log(options);
    axios
      .get(`${CONSTANTS.BaseURL}${uri}`, options)
      .then(({ data }: AxiosResponse) => {
        if (uri === 'vehicle/export') {
          // if(data.size)
          // {
          //   const downloadUrl:string = URL.createObjectURL(data);
          //   console.log(downloadUrl);
          //   window.location.href = downloadUrl;
          //   toast.success("Downloaded successfully.", {
          //     position: toast.POSITION.TOP_RIGHT
          //    });
          //   resolve({
          //     status:true,
          //     message:"Downloading",
          //     data:res.data
          // })
          // }else{
          //  toast.error("Oops! something went wrong.", {
          //         position: toast.POSITION.TOP_RIGHT
          //        });
          // resolve({
          //         status:false,
          //         message:"Oops! something went wrong.",
          //         data:{}
          // })
          //   }
        } else {
          if (data.status) {
            if (success) {
              toast.success(data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            resolve(data);
          } else {
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            resolve(data);
          }
        }
      })
      .catch((error) => {
        console.log('error.response.data:', error);
        if (success) {
          toast.error(
            error?.response?.data
              ? error?.response?.data.message
              : error.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
        resolve({
          status: false,
          message: error.message,
          data: {},
        });
      });
  });
};
export const ValidateEmail = (value: string) => {
  const valid = value.match(
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return valid;
};
export function RemoveSpecialCharaters(d: string) {
  d = String(d).trim();
  return d.replace(/[-+,&\/\\#()$~%.;'":*?<>{}@ ]/g, '');
}
export function ReturnAllNumbers(d: string) {
  d = String(d).trim();
  return d.replace(/[-+,&\/\\#()$~%.;'":*?<>{}A-Z a-z]/g, '');
}
export function ReturnUsername(d: string) {
  d = String(d).trim();
  return d.replace(/[-+,&\/\\#()$~%.;'":*?<>{} 0-9]/g, '');
}
export function ReturnAccountUsername(d: string) {
  d = String(d).trim();
  return d.replace(/[-+,&\/\\#()$~%.;'":*?<>{} ]/g, '');
}
export function ReturnAllNumbersWithComma(d: string) {
  d = String(d).trim();
  return d.replace(/[-+&\/\\#()$~%.;'":*?<>{}A-Z a-z]/g, '');
}
export function ReturnMobile(d: string) {
  d = String(d).trim();
  d = String(d[0]) === '0' ? d.replace('0', '') : d;
  return d.replace(/[-+,&\/\\#()$~%.;'":*?<>{}A-Z a-z]/g, '');
}
export function ReturnAllLetter(d: string) {
  d = String(d).trim();
  return d.replace(/[-+,&\/\\#()$~%.;'":*?<>{} 0-9]/g, '');
}
export function ReturnComma(str: string) {
  if (str === '' || str === ' ' || `${str[0]}` === '0') {
    return '';
  }
  if (str === '.') {
    return String(str).replace('.', '0');
  }

  str = String(str).replace(/[^0-9.]/g, '');
  var getDot = String(str).split('.');
  var firstPart = getDot[0];
  if (firstPart.length >= 4) {
    firstPart = firstPart.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  if (getDot.length >= 2) {
    return firstPart + '.' + getDot[1];
  }
  if (String(firstPart) === '.00') {
    return '';
  }
  return firstPart;
}
export const ShowError = (message:string)=>{
  toast.error(message, {
    position:"center"
      ? toast.POSITION.TOP_CENTER
      : toast.POSITION.TOP_RIGHT,
  });
}
export const ShowSuccess = (message:string)=>{
  toast.success(message, {
    position:"center"
      ? toast.POSITION.TOP_CENTER
      : toast.POSITION.TOP_RIGHT,
  });
}
export const uploadImageToCloud = (file:File)=> {
  return  new Promise<APIResponse>((resolve)=>{
       const formData = new FormData();
       formData.append("file", file);
       formData.append("upload_preset", "gearup");
       formData.append("cloud_name", "dhly0jxj2"); 
       const cloudinaryUrl = "https://api.cloudinary.com/v1_1/dhly0jxj2/image/upload";  // Replace 'your_cloud_name' with your actual cloud name
       fetch(cloudinaryUrl, {
           method: 'POST',
           body: formData
       })
       .then(response => response.json())
       .then(data => {
           console.log('Upload successful:', data,"Image URL:", data.secure_url);
           resolve({status:data.error?false:true,message:data.error?data.error.message:'Upload successful:.',data:data.error?"x":data.secure_url})
       })
       .catch(error => {
         console.log("Upload:",error);
           resolve({status:false,message:'Error uploading image:',data:null})
       });
   })
 }