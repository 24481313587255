/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { RefObject, useEffect, useRef, useState } from 'react'
import { BoxIcon, CalendarIcon, CaretDownIcon } from '../icon'
import './../style.css';
import { AddBusinessComponent } from '../components/addPersonnel';
import { ImportPersonnelComponent } from '../components/importPersonnel';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend,} from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { ChartDataProps, HistoryProps } from '../../../includes/types';
import { GetRequest, PostRequest } from '../../../includes/functions';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { CONSTANTS } from '../../../includes/constant';
import { CalendarComponent } from '../../../components/Calender';
import { BaseLoader } from '../../../components/baseloader';
import { ChartBoard } from '../components/chartSection';
import { AutomaticStatus, ManualStatus } from './businessDetails';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export interface TabProps {
  class: string;
  title: string;
  amount: number;
}

export default function DashboardSection() {
  const tableFilter: string[] = ["Combined", "Check-Ins", "Check-Outs"];
  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const [searching, setSearching] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<string>(moment().subtract(3, "months").toISOString());
  const [endDate, setEndDate] = useState<string>(moment().toISOString());
  const [showAddBusiness, setshowAddBusiness] = useState<boolean>(false);
  const [showImportPersonnel, setShowImportPersonnel] = useState<boolean>(false);
  const [totalEmployees, setTotalEmmployees] = useState<number>(0);
  const [totalCheckIn, setTotalCheckIn] = useState<number>(0);
  const [totalCheckOut, setTotalCheckOut] = useState<number>(0);
  const [allCompaniesCount, setAllCompaniesCount] = useState<number>(0);
  const [chartCheckInData, setchartCheckInData] = useState<ChartDataProps>(
    {
      data: [],
      labels: []
    }
  )
  const [chartCheckOutData, setchartCheckOutData] = useState<ChartDataProps>(
    {
      data: [],
      labels: []
    }
  )

  const [selectedTab, setSelectedTab] = useState<string>(tableFilter[0])

  const AllCheckInCheckOut = () => {
    GetRequest("trip/check-in", {}, false).then((res) => {
      if (res.status) {
        setTotalCheckIn(res.data?.totalCheckIns);
      }
    })
    GetRequest("trip/check-out", {}, false).then((res) => {
      if (res.status) {
        setTotalCheckOut(res.data.totalCheckOuts);
      }
    })
  }

  const AllCompanies = () => {
    GetRequest("businesses?itemsPerPage=1000&pageNumber=1", {}, false).then((res) => {
      console.log(res);
      if (res.status) {
        setAllCompaniesCount(res.data.businesses.length);
        var numberOfEmployees: any[] = [];
        res.data.businesses.forEach((a: any, i: number) => {
          numberOfEmployees = [...numberOfEmployees, ...a.employees]
        })
        setTotalEmmployees(numberOfEmployees.length);
        console.log(numberOfEmployees);
      }
    })
  }

  const AllEmployees = () => {
    GetRequest("employees?itemsPerPage=1000&pageNumber=1", {}, false).then((res) => {
      if (res.status && res.data.users) {
        setTotalEmmployees(res.data.users.length)
      }
    })
  }

  const AllChartData = () => {
    setSearching(true)
    GetRequest("admin/attendence/report", {
      startDate: moment(startDate).format("YYYY-MM-DD").replace("Invalid date","N/A"),
      endDate: moment(endDate).format("YYYY-MM-DD").replace("Invalid date","N/A")
    }, false).then((res) => {
      setSearching(false)
      var selectMonths: string[] = [];
      var countIntervalsCheckIn: number[] = [];
      var countIntervalsCheckOut: number[] = [];
      if (res.status) {
        console.log('API to get attendance that i know not of', res)
        res.data.forEach((a: any, i: number) => {
          const month = moment(a.checkIns[0]).format("MMM");
          selectMonths.push(month);
          countIntervalsCheckIn.push(a.checkIns.length);
          countIntervalsCheckIn.push(100);
          countIntervalsCheckOut.push(a.checkOuts.length);
          countIntervalsCheckOut.push(100);
        })
        setchartCheckInData({
          data: countIntervalsCheckIn,
          labels: selectMonths
        })
        setchartCheckOutData({
          data: countIntervalsCheckOut,
          labels: selectMonths
        })
      } else {
        const month = moment(startDate).format("MMM").replace("Invalid date","N/A");
        setchartCheckInData({
          data: [0, 100],
          labels: [month]
        })
        setchartCheckOutData({
          data: [0, 100],
          labels: [month]
        })
      }
    })
  }
  const handleSelect = (date: Date) => {
    console.log(date); // native Date object
  }

  useEffect(() => {
    AllCompanies();
    AllCheckInCheckOut();
    AllChartData();
    AllEmployees();
    thisView.current?.addEventListener("mouseleave", () => {
      setShowCalendar(false);
    })
  }, [startDate, endDate]);

  const tabs: TabProps[] = [
    { class: "light-gray-card", title: "Total Businesses", amount: allCompaniesCount },
    { class: "light-yellow-card", title: "Total Employees", amount: totalEmployees },
    { class: "pink-card", title: "Total Check-ins", amount: totalCheckIn },
    { class: "gray-card", title: "Total Check-outs", amount: totalCheckOut }
  ];

  const thisView = useRef() as RefObject<HTMLDivElement>

  return <div className='main-scrollable p-5 pt-0' >
    <div className='row' >
      <div className='col-8' >
        <div className="title-text">Welcome back 😊</div>
        <div className="">Here's an overview of all activities</div>
      </div>
      <div className='col-4' >
        <div
          ref={thisView}
          className='position-relative'>
          <div
            className='cursor-pointer'
            onClick={() => {
              setShowCalendar(!showCalendar);
            }}
          >
            <div className='bx'

            >
              {searching ? <BaseLoader /> : <CalendarIcon />}
              <span className='tx'>{moment(startDate).format("MMM DD, YYYY").replace("Invalid date","N/A")} - {moment(endDate).format("MMM DD, YYYY").replace("Invalid date","N/A")}</span>
              <span className='caret'
              >
                <CaretDownIcon />
              </span>
            </div>
          </div>
          {showCalendar && <CalendarComponent
            calenderPosition="left"
            onClose={() => {
              setShowCalendar(false)
            }}
            startDate={moment().subtract(3, "M").format("MM-DD-YYYY").replace("Invalid date","N/A")}
            onValue={({ startDate, endDate }) => {
              setEndDate(endDate)
              setStartDate(startDate)
              setShowCalendar(false)
              setTimeout(() => {
                AllChartData()
              }, 1000)
            }}
          />}
        </div>
      </div>
    </div>
    <ChartBoard
      tabs={tabs}
    />
    <div className='row pt-5 pb-3' >
      <div className='col-12' >
        <div className='card' style={{ minHeight: 370, position: 'relative' }} >
          <div className='row p-3'>
            <div className='col-4' >
              <div className='chart-title'>TREEPZ HISTORY</div>
              <div className='chart-date'>{moment(startDate).format("MMM DD, YYYY").replace("Invalid date","N/A")} - {moment(endDate).format("MMM DD, YYYY").replace("Invalid date","N/A")}</div>
            </div>
            <div className='col-8 pe-3' >
              <div className='chart-tabs-container row'>
                {tableFilter.map((a, i) => <div key={i}
                  onClick={() => {
                    setSelectedTab(a);
                  }}
                  className={`col-4 ${selectedTab === a ? "chart-tabs-active" : ""}`}>{a}</div>)}
              </div>
            </div>
          </div>
          <div className='px-3' style={{ position: 'relative', height: 360, overflow: "hidden" }} >
            {selectedTab === "Check-Ins" || selectedTab === "Combined" ? <div style={{ height: "100%" }}>
              <Bar
                data={{
                  labels: chartCheckInData.labels,
                  datasets: [
                    {
                      label: "",
                      data: chartCheckInData.data,
                      backgroundColor: "#FA6533",
                      maxBarThickness: 30
                    }
                  ]
                }}
                color="red"
              />
            </div> : null}
            {selectedTab === "Check-Outs" || selectedTab === "Combined" ? <div style={{ position: 'absolute', left: 40, top: -18, width: 580, height: 370 }}>
              <Bar
                options={
                  {
                    scales: {
                      x: {
                        display: selectedTab === "Check-Outs" ? true : false,
                      },
                      y: {
                        display: selectedTab === "Check-Outs" ? true : false,
                      }
                    }
                  }}
                data={{
                  labels: chartCheckOutData.labels,
                  datasets: [
                    {
                      label: "",
                      data: chartCheckOutData.data,
                      backgroundColor: "#F8B02B",
                      maxBarThickness: 20,
                    }
                  ]
                }}
                color="red"
              />
            </div> : null}
          </div>
          <div className='d-flex align-items-center justify-content-end' style={{ position: "absolute", bottom: 10, left: 0, zIndex: 999999, width: "100%" }}>
            <div className='c-box-checkin'></div>
            <span className='px-2 cb-txt'>Check-ins</span>
            <div className='c-box-checkout'></div>
            <span className='px-2 cb-txt'>Check-outs</span>
          </div>
        </div>
      </div>
      {/* <div className='col-4' >
        <div className='light-gray-card' >
          <div className='row'>
            <div className='col-8'>ACTIVITY FEED</div>
            <div className='col-4 text-end '>
              <small className='btn orange-text p-0 fs-13 underlined'>See all</small>
            </div>
          </div>
          <hr style={{ backgroundColor: "#ddd", margin: 1, marginTop: 5 }} />
          <ActivityFeedComponent />
        </div>
      </div> */}
    </div>
    <TableSection />
    {showAddBusiness && <AddBusinessComponent
      onClose={() => setshowAddBusiness(false)}
    />}
    {showImportPersonnel && <ImportPersonnelComponent
      onClose={() => setShowImportPersonnel(false)}
    />}
  </div>
}

const TableSection = () => {
  const [historyList, setHistoryList] = useState<HistoryProps[]>([]);
  const [startDate, setStartDate] = useState<string>(moment().subtract(3, "M").toISOString());
  const [endDate, setEndDate] = useState<string>(moment().toISOString());
  const [pageSize, setPageSize] = useState<number>(50);
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null);

  const GetRecentTrips = async () => {
    setLoading(true);
    try {
      const res = await GetRequest('trips?itemsPerPage=10&pageNumber=1', {}, false);
      console.log("API Response:", res);
      if (res.status && res.data && Array.isArray(res.data.trips)) {
        console.log("API Response Trips:", res.data.trips);
        setHistoryList(res.data.trips);
      }
    } catch (error) {
      console.error("Failed to fetch employees' trip history:", error);
      setError("Failed to fetch employees' trip history.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    GetRecentTrips();
  }, [])

  return (<>
    <div className="d-flex align-items-center mb-3">
      <span className='table-title'>Recent treepzs</span>
      <span className='bx ms-3 d-flex align-items-center justify-content-center'
        style={{ width: 90, height: 30 }}
      ><span style={{ fontSize: 14 }}>
          <NavLink to={CONSTANTS.Routes.TreepzHistory}>View all</NavLink>
        </span>
      </span>
    </div>

    {!loading && (
      <table className="table">
        <thead>
          <tr>
            <th scope="col fs-13"><span className='fs-13'>S. No.</span></th>
            <th scope="col"><span className='fs-13'>Employee ID</span></th>
            <th scope="col"><span className='fs-13'>Employee Name</span></th>
            <th scope="col"><span className='fs-13'>Date</span></th>
            <th scope="col"><span className='fs-13'>Check-In</span></th>
            <th scope="col"><span className='fs-13'>Check-Out</span></th>
            <th scope="col"><span className='fs-13'>Log type</span></th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan={10}>
                <BaseLoader /> <small className='fs-small'>Fetching...</small>
              </td>
            </tr>
          )}

          {!loading && historyList.length === 0 && (
            <tr>
              <td colSpan={10} style={{ textAlign: 'center' }}>
                <BoxIcon />
                <p>No recent treepz history.</p>
              </td>
            </tr>
          )}

          {!loading && historyList.length > 0 && historyList.filter((trips, i) => i < 5).map((trip, i) => (
            <tr key={i}>
              <td>{(i + 1) > 9?(i + 1):"0"+(i + 1)}</td>
              <td>{trip.employeeId}</td>
              <td>{trip?.rider}</td>
              <td>{moment(trip.createdAt).format("Do, MMM YYYY").replace("Invalid date","N/A")}</td>
              <td>{moment(trip.checkInTime).format("hh:mm A").replace("Invalid date","N/A")}</td>
              <td>{moment(trip.checkOutTime).format("hh:mm A").replace("Invalid date","N/A")}</td>
              <td>{trip.checkoutType !== "Manual"?<AutomaticStatus />:<ManualStatus />}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </>
  );
};

const ActivityFeedComponent = () => {
  const [list, setList] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ])

  return <div className='p-3' style={{ height: 383, overflowX: "scroll" }} >
    {list.map((a, i) => <div key={i} className='row mb-3'>
      <div className='col-12 position-relative'>
        <div className='fw-bold fs-13'>
          Dara Balance of Wakanow Limited
        </div>
        <div className='fs-13'>checked-in</div>
        <div className='fs-12 light-gray-text'>Feb 12,2023 7:01AM</div>
        <div className='position-absolute right-0 top-10'>
          <OrangeDot />
        </div>
      </div>

    </div>)}
  </div>
}

const OrangeDot = () => {
  return <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="8" height="8" rx="4" fill="#FA6533" />
  </svg>
}


// const ActivityFeedComponent = () => {
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);
//   const [list, setList] = useState<string[]>([]);

//   useEffect(() => {
//     setLoading(true);
//     // Simulate API call for fetching the activity feed
//     GetRequest("api/activity-feed", {}, false).then((res) => {
//       setLoading(false);
//       if (res.status) {
//         setList(res.data.activities);
//       } else {
//         setError("Failed to load activity feed.");
//       }
//     }).catch(() => {
//       setLoading(false);
//       setError("An error occurred while fetching data.");
//     });
//   }, []);

//   if (loading) return <BaseLoader />;
//   if (error) return <div className="error-message">{error}</div>;

//   return (
//     <div className="p-3" style={{ height: 383, overflowX: "scroll" }}>
//       {list.length === 0 ? (
//         <div className="text-center">No recent activity</div>
//       ) : (
//         list.map((activity, i) => (
//           <div key={i} className="row mb-3">
//             <div className="col-12 position-relative">
//               <div className="fw-bold fs-13">{activity.user}</div>
//               <div className="fs-13">{activity.action}</div>
//               <div className="fs-12 light-gray-text">{moment(activity.date).format('MMM DD, YYYY h:mm A')}</div>
//               <div className="position-absolute right-0 top-10">
//                 <OrangeDot />
//               </div>
//             </div>
//           </div>
//         ))
//       )}
//     </div>
//   );
// };

// const OrangeDot = () => {
//   return (
//     <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <rect width="8" height="8" rx="4" fill="#FA6533" />
//     </svg>
//   );
// };
