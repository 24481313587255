/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FormEvent, useEffect, useState } from 'react';
import { BaseButton } from '../../../../components/buttons';
import BaseInput from '../../../../components/baseInput';
import { Formik } from 'formik';
import * as y from 'yup';
import { BusinessProps, CountryProps, EmployeesProp } from '../../../../includes/types';
import { GetRequest, PostRequest } from '../../../../includes/functions';
import BaseSelectInput from '../../../../components/baseSelectInput';
import { ItemProps } from '../../sections/HistoryTables/invitations';
import { RoleProps } from '../../sections/rolesAndPermissions';

const schema = y.object({
  role: y
    .string()
    .required('Role is required.')
});
interface ConfirmDialogComponentProps {
  onClose: (status?:boolean) => void;
  onValue: (v: any) => void;
  employeeData: BusinessProps | null;
  loading?: boolean;
  businessDetails?:BusinessProps;
}
export const EditUserComponent = (props: ConfirmDialogComponentProps) => {
  const [formData,setFormData] = useState<BusinessProps>(props.employeeData!)
  const [loading,setLoading] = useState<boolean>(false)
  const listOfCountry:CountryProps[] = [
    {name:"Nigeria",
      cities:[
        "Abuja", "Kano", "Kaduna", "Maiduguri", "Sokoto", "Minna", "Jos", "Zaria", "Katsina", "Birnin Kebbi",
        "Lagos", "Ibadan", "Abeokuta", "Osogbo", "Akure", "Ilorin", "Benin City", "Oyo", "Shagamu",
        "Port Harcourt", "Calabar", "Uyo", "Warri", "Enugu", "Onitsha", "Aba", "Owerri", "Umuahia",
        "Abakaliki", "Ile-Ife", "Sapele", "Lokoja", "Makurdi", "Ifo", "Jalingo", "Gombe", "Yola", "Kogi",
        "Kano", "Bauchi", "Sokoto", "Kaduna", "Ilorin", "Akure", "Abeokuta"
    ]},
    {name:"Ghana",
      cities: [
        "Accra", "Kumasi", "Takoradi", "Tamale", "Ashaiman", "Sekondi", "Cape Coast", "Koforidua", 
        "Sunyani", "Ho", "Tema", "Mampong", "Techiman", "Akim Oda", "Tarkwa", "Obuasi", "Akosombo", 
        "Berekum", "Sefwi Wiawso", "Nkoranza", "Bolgatanga", "Wa", "Chirano", "Bawku", "Ejisu", "Nungua"
    ]},
    {name:"Uganda",
      cities:[
        "Kampala", "Entebbe", "Mbarara", "Jinja", "Mbale", "Masaka", "Fort Portal", "Lira", "Gulu", 
        "Kabale", "Arua", "Kasese", "Soroti", "Kampala", "Masindi", "Bushenyi", "Hoima", "Nakasongola", 
        "Kiryandongo", "Pader", "Kyenjojo", "Kagadi", "Iganga", "Namutumba", "Buhweju", "Bombo", 
        "Kotido", "Kaliro", "Rakai", "Kassanda", "Nakaseke"
    ]},
    {name:"Kenya",
      cities:[
        "Nairobi", "Mombasa", "Kisumu", "Nakuru", "Eldoret", "Thika", "Kisii", "Machakos", 
        "Malindi", "Nyeri", "Meru", "Embu", "Kericho", "Kitale", "Voi", "Kakamega", 
        "Bungoma", "Lamu", "Murang'a", "Nandi", "Ruiru", "Kiambu", "Homabay", "Bomet", 
        "Siaya", "Migori", "Narok", "Garissa", "Wajir", "Mandera", "Baringo", "Taveta"
    ]},
    {name:"Canada",
      cities: [
        "Toronto", "Vancouver", "Montreal", "Calgary", "Ottawa", "Edmonton", "Winnipeg", 
        "Quebec City", "Hamilton", "Kitchener", "London", "Victoria", "Halifax", "Surrey", 
        "Burnaby", "Richmond", "Markham", "Laval", "Brampton", "Oshawa", "Burlington", 
        "St. John's", "Gatineau", "Saskatoon", "Regina", "Mississauga", "Kingston", "Abbotsford", 
        "Red Deer", "Thunder Bay", "Chicoutimi", "Prince George", "Saint-Jean-sur-Richelieu", 
        "Saint John", "Moncton", "Sherbrooke", "Niagara Falls", "Fredericton", "Sudbury", 
        "Peterborough", "Kamloops", "Lethbridge", "Nanaimo", "Courtenay", "New Westminster"
  ]}
  ];
  var list:any[] = listOfCountry.filter((a,i)=>a.name === formData.country);
  var Cities:ItemProps[] = [];
  if(list.length !== 0 )
  {
    Cities = list[0].cities.map((a:string,i:number)=>{
      return {
        title:a,
        value:a
      }
    });
  }
  useEffect(()=>{
    const country = listOfCountry.find((a,i)=>a.name === props.employeeData?.country);
      setFormData({
        ...formData,
        ...country
      })
    },[props.employeeData])

    const handleSubmit =(e:FormEvent) => {
      e.preventDefault();
      const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      phoneNumber: formData.phoneNumber
      }
      setLoading(true);
    PostRequest(`put:employee/${props?.employeeData?._id}`, data, true).then((response) => {
      setLoading(false);
        if (response.status) {
          props.onClose(true);
        }
      })
    }
  return (
    <div className="modal" tabIndex={-1}>
      <div className="modal-dialog">
        <div
          className="modal-content"
          style={{ borderRadius: 20, marginTop: 100 }}
        >
          <div className="modal-header">
            <h5 className="modal-title">{props.employeeData?.action  === "view"?"Employee details":"Edit employee details"}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={()=>props.onClose()}
            ></button>
          </div>
          <div className="modal-body p-3">
            <form
            onSubmit={handleSubmit}
            >
                <div className="">
                  <BaseInput
                    label="Employee's ID"
                    disabled={true}
                    name="employeeId"
                    type="text"
                    placeholder="2345464IG"
                    onValueChange={()=>{}}
                    value={formData.employeeId}
                    required={true}
                   />
                  <BaseInput
                    label="First name"
                    name="firstName"
                    type="text"
                    disabled={props.employeeData?.action  === "view"}
                    placeholder="John"
                    onValueChange={({target})=>{
                      setFormData({...formData,firstName:target.value})
                    }}
                    value={formData.firstName}
                    required={true}
                  />
                   <BaseInput
                    label="Last name"
                    name="lastName"
                    type="text"
                    disabled={props.employeeData?.action  === "view"}
                    placeholder="Paul"
                    onValueChange={({target})=>{
                      setFormData({...formData,lastName:target.value})
                    }}
                    value={formData.lastName}
                    required={true}
                  />
                   <BaseInput
                    label="Phone Number"
                    name="phoneNumber"
                    type="text"
                    max={11}
                    disabled={props.employeeData?.action  === "view"}
                    placeholder="0900000000"
                    onValueChange={({target})=>{
                      setFormData({...formData,phoneNumber:target.value})
                    }}
                    value={String(formData.phoneNumber!).replace(/[^\d+$]/g,'').replace(/[+]/g,'')}
                    required
                  />
                  <BaseInput
                    label="Employee's work email"
                    name="email"
                    type="email"
                    disabled={true}
                    placeholder="Employee's work email"
                    onValueChange={()=>{
                      return ;
                    }}
                    value={props.employeeData?.email}
                    required={true}
                  />
                  {props.employeeData?.action  !== "view" &&<div className="p-3 row" style={{ paddingRight: 30 }}>
                    <BaseButton 
                    type='submit'
                    loading={loading} 
                    >
                      Update employee information
                    </BaseButton>
                  </div>}
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
interface FormDataProp {
 role?:string;
}
export const EditRoleComponent = (props: ConfirmDialogComponentProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [formData,setFormData] = useState<FormDataProp>({
    role:""
  })
  const [listOfRoles, setListOfRoles] = useState<RoleProps[]>([]);
 
  const GetRoles = ()=>{
    GetRequest("role",{}).then((res)=>{
      if(res.status)
      {
        const RoleList: RoleProps[] = res.data.roles;
        setListOfRoles(RoleList);
      }
    })
  }
  const handleSubmit =(e:FormEvent)=>{
    e.preventDefault();
    setLoading(true);
    const data = {
      role:formData.role
    }
    PostRequest(`put:admin/change-role/${props.employeeData?._id}`,data,true).then((res)=>{
      setLoading(false);
      if(res.status)
    {
      props.onClose()
    }
    })
    }
  useEffect(()=>{
    GetRoles();
  },[])
  return (
    <div className="modal" tabIndex={-1}>
      <div className="modal-dialog">
        <div
          className="modal-content"
          style={{ borderRadius: 20, marginTop: 100 }}
        >
          <div className="modal-header">
            <h5 className="modal-title">Change role</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={()=>props.onClose()}
            ></button>
          </div>
          <div className="modal-body p-3">
            <form
              onSubmit={handleSubmit}
            >
              <div className="">
                  <BaseInput
                    label="Email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    disabled
                    onValueChange={({target})=>{
                    }}
                    value={props.employeeData?.email}
                    required={true}
                  />
                  <BaseSelectInput
                    label="Role"
                    name="role"
                    placeholder="Enter role"
                    onValueChange={(value)=>{
                      setFormData({...formData,role:value?.value})
                    }}
                    value={formData.role!}
                    required={true}
                    options={listOfRoles.map((a,i)=>{
                      return {
                        title:a.name!,
                        value:a._id!
                      }
                    })}
                  />
                  <div className="p-3 row" style={{ paddingRight: 30 }}>
                    <BaseButton 
                    type='submit'
                    loading={loading} 
                     >
                      Update information
                    </BaseButton>
                  </div>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
