import React, { memo } from 'react';
import { RedButton } from '../../../../components/buttons';
import { BusinessProps } from '../../../../includes/types';

interface ConfirmDialogComponentProps {
  loading?: boolean;
  onClose: () => void;
  confirm: () => void;
  title?: string;
  message?: string;
  data?:BusinessProps;
}
export const ConfirmDialogComponent = memo((props: ConfirmDialogComponentProps)=>{
    return <div className="modal" tabIndex={-1}>
      <div className="modal-dialog">
        <div
          className="modal-content"
          style={{ borderRadius: 20, marginTop: 100 }}
        >
          <div className="modal-header">
            <h5 className="modal-title">
              {props.title ? props.title : `Confirm to ${props.data?.action}`}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={props.onClose}
            ></button>
          </div>
          <div className="modal-body p-3">
            <div
              dangerouslySetInnerHTML={{
                __html: props.message
                  ? props.message
                  : `This action is irreversible! <br/>Are you sure you want to ${props.title}`,
              }}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={props.onClose}
              className="btn btn-inactive"
            >
              No
            </button>
            <RedButton
              loading={props.loading}
              style={{ minWidth: 130 }}
              onClick={props.confirm}
            >
              Yes
            </RedButton>
          </div>
        </div>
      </div>
    </div>})
