import { FormEvent, useState } from "react"
import BaseInput from "../../../../components/baseInput";
import { BaseButton } from "../../../../components/buttons";
import BaseSelectInput from "../../../../components/baseSelectInput";
import { PostRequest } from "../../../../includes/functions";
interface FormDataProp {
  companyName?:string;
  companyEmail?: string;
  role?: string;
}

interface AddBusinessComponentprops {
  onClose: () => void;
}
export const AddBusinessComponent = (props: AddBusinessComponentprops) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [formData,setFormData] = useState<FormDataProp>({
    companyName:"",
    companyEmail:"",
    role:""
  })
  const handleSubmit =(e:FormEvent)=>{
    e.preventDefault();
    setLoading(true)
    PostRequest(`business/invite`,{
        companyEmail: formData.companyEmail,
       companyName:formData.companyName
    },true).then((response)=>{
    setLoading(false)
    if(response.status)
    {
      props.onClose()
    }
    })
    }
    
  return <div className="modal" tabIndex={-1} >
    <div className="modal-dialog" >
      <div className="modal-content" style={{ borderRadius: 20 }}>
        <div className="modal-header">
          <h5 className="modal-title">Invite business</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.onClose}></button>
        </div>
        <div className="modal-body p-3">
          <form
          onSubmit={handleSubmit}
          >
              <div className="pt-3" >
                <BaseInput
                  label="Company Name"
                  name='CompanyName'
                  type='text'
                  max={20}
                  placeholder="e.g Wakanow Limited"
                  onValueChange={({target})=>{
                    setFormData({...formData,companyName:target.value});
                  }}
                  value={formData.companyName}
                  required
                  />
                <BaseInput
                  label="Company email"
                  name='email'
                  type='email'
                  max={50}
                  placeholder="Company email"
                  onValueChange={({target})=>{
                    setFormData({...formData,companyEmail:target.value});
                  }}
                  value={formData.companyEmail}
                  required
                     />
               
              </div>
              <div className="row px-3">
                <BaseButton
                  loading={loading}
                  style={{ minWidth: 130 }}
                 type="submit"
                >Send invitation
                </BaseButton>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
}