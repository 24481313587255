/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect,useState } from 'react'
import './style.css';
import { ItemProps } from '../../screens/dashboard/sections/HistoryTables/invitations';
interface BaseSelectProps {
  options:ItemProps[];
  onValueChange:(data:ItemProps)=>void;
  placeholder?:string;
  required?:boolean;
  name:string;
  label:string;
  value:string;
}

 const BaseSelectInput = (props:BaseSelectProps)=>{
const [selected,setSelected] = useState<string | null>("");
const [list,setList] = useState<ItemProps[]>([]);
useEffect(()=>{
    if(selected !== "" && props.placeholder)
    {
    setList(list.filter((a,i)=>a.title !== props.placeholder));
    }else{
    setList(list.filter((a,i)=>a.title !== "Select an option"));
    }
},[selected])
useEffect(()=>{
setList([{
    value: "",
    title: `${props.placeholder ? props.placeholder : "Select an option"}`,
    },...props.options.map((a,i)=>{
        console.log("selected:",a.title === props.value,"|",props.value+"|"+a.title);
        return {
            ...a,
            selected:a.title === props.value
        }
    })])
},[props.options])
return <div className="mb-3">
<div className={`form-control radius-8`}>
<label className="form-label-x" >{props?.label}</label>
<select 
required={props.required}
onChange={({target})=>{
     setSelected(target.value)
     const found = list.find((a,i)=>a.value === target.value)
     if(found)
    {
     props.onValueChange(found)
    }
}}
className="noborder" 
aria-label="Select an option">
{list.map((a,i)=><option 
selected={a.selected} 
key={i} 
value={a.value} 
>{a.title}</option>)}
</select>
</div>
</div>
}
export default BaseSelectInput;