/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEventHandler, RefObject, useEffect, useRef, useState } from 'react';
import { BaseButton, WhiteButton } from '../../../components/buttons';
import { CaretDownIcon, ExportIcon, FilterIcon, SettingsIcon } from '../icon';
import { Menu } from '../sections/HistoryTables/invitations';
import { CloseButton } from 'react-toastify/dist/components';
import { CloseIcon } from '../../../assets/icons/CloseIcon';
import Calendar from 'react-calendar';
import { CalendarComponent } from '../../../components/Calender';
import moment from 'moment';

interface SearchBarProps {
  calenderPosition: 'left' | 'right';
  onSearch: (v: string) => void;
  onAddEmployee: () => void;
  onImportBusiness: () => void;
  onExportPersonnel: () => void;
  page: 'data' | 'history';
  showFilter?: boolean;
  processing?: boolean;
  onFilterValue?: (values: { startDate: string; endDate: string }) => void;
}
export const EmployeeBar = (props: SearchBarProps) => {
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [showColumn, setShowColumn] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');
  const thisPopUp = useRef(null) as RefObject<HTMLSpanElement>;
  useEffect(() => {
    thisPopUp.current?.addEventListener('mouseleave', () => {
      setShowCalendar(false);
    });
    return () => { };
  }, [showCalendar]);
  return (
    <div className="row pb-3">
      <div className=''>
        <div className="d-flex align-items-center justify-content-end">
          <WhiteButton
            loading={props.processing}
            onClick={props.onExportPersonnel}>
            <ExportIcon />
            <span className="ps-2">Export CSV</span>
          </WhiteButton>
          {/* <Menu 
          type='new'
          onValue={(d)=>{
            if(d.action === "add")
            {
                props.onAddEmployee()
            }
            if(d.action === "import")
            {
                props.onImportBusiness()
            }
        }}
          > */}
          <Menu
            type="new"
            onValue={(d) => {
             if (d.action === 'add') {
              props.onAddEmployee();
             }
             if (d.action === 'import') {
              props.onImportBusiness();
              }
              }}
            >
            <div
              className="btn base-button d-flex align-items-center justify-content-center"
              onClick={() => {
               
              }}
              style={{ paddingLeft: 5, paddingRight: 5, width: 180 }}
            >
              <span className="ps-2">Add new employee</span>
              <CaretDownIcon />
            </div>
            </Menu>
          {/* </Menu> */}
        </div>
      </div>
    </div>
  );
};
