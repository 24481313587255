/* eslint-disable react-hooks/exhaustive-deps */
import { RefObject, useEffect, useRef, useState } from 'react';
import './../style.css';
import { Menu } from './HistoryTables/invitations';
import { Pagination } from '../../../components/pagination';
import ThreeVerticalDotsIcon from '../../../assets/icons/threeDots';
import { GetRequest, PostRequest } from '../../../includes/functions';
import { VehicleProps } from '../../../includes/types';
import moment from 'moment';
import { DownloadIcon } from '../../../assets/icons/DownloadBtn';
import { BaseLoader } from '../../../components/baseloader';
import { HistorySearchBar } from '../components/HistorySearchBar';
import { EditVehicleComponent } from '../components/editVehicle';
import { QrcodeGeneratorComponent } from '../components/QrCode';
import { BaseButton } from '../../../components/buttons';
import { AddVehicleComponent } from '../components/addVehicle';
import {
  subscribeEvent,
  unsubscribeEvent,
} from '../../../includes/customEventHook';
import { CONSTANTS } from '../../../includes/constant';
import { DownloadCSV } from '../../../includes/createCSVFileHook';
import { toast } from 'react-toastify';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import VehicleDetailsSection from './vehiclesDetails';
import { ConfirmDialogComponent } from '../components/confirmDialog';

export default function TreepzHistorySection() {
  const navigate = useNavigate();
  const [processing, setProcessing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showAddVehicle, setShowAddVehicle] = useState<boolean>(false);
  const [editVehicle, setShowEditVehicle] = useState<VehicleProps | null>(null);
  const [vehicleDetails, setVehicleDetails] = useState<VehicleProps | null>(
    null
  );
  const [filterString, setFilterString] = useState<string>('');
  const [showImportPersonnel, setShowImportPersonnel] =
    useState<boolean>(false);
  const [vehicleList, setVehicleList] = useState<VehicleProps[]>([]);
  const [startDate, setStartDate] = useState<string>(
    moment().subtract(3, 'M').toISOString()
  );
  const [endDate, setEndDate] = useState<string>(moment().toISOString());
  const [pageSize, setPageSize] = useState<number>(50);
  const [showQrCode, setShowQrCode] = useState<boolean>(false);
  const [selectVehicleId, setSelectVehicleId] = useState<string>('');
  const [showDeleteVehicle, setShowDeleteVehicle] = useState<boolean>(false);

  const handleExport = () => {
    DownloadCSV({ data: vehicleList, fileName: 'vehicle_list' });
  };

  const GetAllVehicles = (page: number) => {
    setLoading(true);
    GetRequest(
      `vehicles?itemsPerPage=${pageSize}&pageNumber=${page}&startDate=${startDate}&endDate=${endDate}`,
      {},
      false
    ).then((res) => {
      setLoading(false);
      console.log(res.data.vehicles);
      if (res.status) {
        setVehicleList(res.data.vehicles);
      }
    })
    .catch((error) => {
      setLoading(false);
      console.error('Error fetching businesses:', error);
    });
  };

  const DeleteVehicle = (id: string) => {
    setSelectVehicleId(id);
    PostRequest(`delete:vehicle/${id}`, {}, true).then((res) => {
      setSelectVehicleId('');
      if (res.status) {
        setShowDeleteVehicle(false);
        setVehicleList(vehicleList.filter((a, i) => a._id !== id));
      }
    });
  };

  const printedSection = useRef() as RefObject<HTMLDivElement>;
  useEffect(() => {
    GetAllVehicles(1);
    subscribeEvent(CONSTANTS.Events.reloadVehicles, () => {
      GetAllVehicles(1);
    });
    return () => {
      unsubscribeEvent(CONSTANTS.Events.reloadVehicles, () => {});
    };
  }, []);

  const PrintInfo = () => {
    if (printedSection.current) {
      var openWindow = window.open('', 'title', 'attributes');
      openWindow?.document.write(printedSection.current?.innerHTML);
      openWindow?.document.close();
      openWindow?.focus();
      openWindow?.print();
      openWindow?.close();
    }
  };

  const [getVehicleId, setGetVehicleId] = useState<string>('');
  let [searchParams, setSearchParams] = useSearchParams();

  const AllItems: VehicleProps[] = vehicleList.filter((a, i) =>
    a.make.includes(filterString)
  );
  if (searchParams.get('id')) {
    return <VehicleDetailsSection id={searchParams.get('id')?.toString()!} />;
  }

  return (
    <div style={{ position: 'relative' }}>
      <div className="main-scrollable p-5 pt-0">
        <div className="heading mb-3">
          <b className="fCap me-3">Vehicles</b>
          <BaseButton
            onClick={() => {
              setShowAddVehicle(true);
            }}
          >
            Add vehicle
          </BaseButton>
        </div>

        <HistorySearchBar
          calenderPosition="right"
          onSearch={(d) => {
            setFilterString(d);
          }}
          page="history"
          onAddBusiness={() => setShowAddVehicle(true)}
          processing={processing}
          onExportPersonnel={() => {
            handleExport();
          }}
          onImportBusiness={() => setShowImportPersonnel(true)}
          showFilter={true}
          onFilterValue={({ startDate, endDate }) => {
            setStartDate(startDate);
            setEndDate(endDate);
          }}
        />

        <table className="table table-responsive">
          <thead>
            <tr>
              <th scope="col">
                <input type="checkbox" />
              </th>
              <th scope="col">S. No.</th>
              <th scope="col">Vehicle make</th>
              <th scope="col">Vehicle model</th>
              <th scope="col">Vehicle year</th>
              <th scope="col">Registration number</th>
              <th scope="col">Chassis number</th>
              <th scope="col">Vehicle tag</th>
              <th scope="col">Date</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={10}>
                  <BaseLoader /> <small className="fs-small">Fetching...</small>
                </td>
              </tr>
            )}
            {AllItems.map((a, i) => (
              <tr key={i}>
                <td>
                  <input type="checkbox" />
                </td>
                <td>{i + 1}</td>
                <td>{a.make}</td>
                <td>{a.vehicleModel}</td>
                <td>{a.year}</td>
                <td>{a.registrationNumber}</td>
                <td>{a.chasisNumber}</td>
                <td>{a.tag}</td>
                <td>{moment(a.createdAt).format('Do, MMM YYYY').replace("Invalid date","N/A")}</td>
                <td style={{ width: 50 }}>
                  {selectVehicleId === a._id ? (
                    <span>
                      <BaseLoader />
                    </span>
                  ) : (
                    <Menu
                      onValue={(value) => {
                        if (value.action === 'delete') {
                          setSelectVehicleId(a._id);
                          setShowDeleteVehicle(true);
                        }
                        if (value.action === 'edit') {
                          setShowEditVehicle(a);
                        }
                        if (value.action === 'view') {
                          localStorage.setItem(
                            CONSTANTS.Routes.Vehicles,
                            JSON.stringify(a)
                          );
                          navigate('?id=' + a._id);
                        }
                        if (value.action === 'qrcode') {
                          setVehicleDetails(a);
                          setShowQrCode(true);
                        }
                      }}
                      type="vehicle"
                    >
                      <ThreeVerticalDotsIcon />
                    </Menu>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          onFilterRow={(d) => {
            setPageSize(d);
          }}
          onPage={(d) => {
            GetAllVehicles(d);
          }}
        />
        {editVehicle && (
          <EditVehicleComponent
            details={editVehicle}
            onClose={() => setShowEditVehicle(null)}
          />
        )}
        {showQrCode && vehicleDetails ? (
          <QrcodeGeneratorComponent
            onClose={() => {
              setShowQrCode(false);
              setVehicleDetails(null);
            }}
            details={vehicleDetails}
          />
        ) : null}
      </div>
      {vehicleDetails && !showQrCode ? (
        <div
          className="main-scrollable p-5 pt-3"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            background: 'white',
            height: '100%',
            width: '100%',
          }}
        >
          <div
            style={{
              width: 200,
              paddingTop: 20,
              paddingLeft: 10,
              height: 60,
              position: 'fixed',
              top: 0,
              left: 360,
              backgroundColor: 'white',
            }}
          >
            <b className="fCap">Trip details</b>
          </div>
          <div className="row">
            <div className="col-9" ref={printedSection}>
              <div className="row pb-3">
                <div className="col-4">Employee ID</div>
                <div className="col-4">{vehicleDetails._id}</div>
              </div>

              <div className="row pb-3">
                <div className="col-4">Employee Make</div>
                <div className="col-4">{vehicleDetails.make}</div>
                <div className="col-4"></div>
              </div>
              <div className="row pb-3">
                <div className="col-4">Email Address</div>
                <div className="col-4">{}</div>
              </div>
              <div className="row pb-3">
                <div className="col-4">Check-In</div>
                <div className="col-4">{}</div>
              </div>
              <div className="row pb-3">
                <div className="col-4">Check-Out</div>
                <div className="col-4">{}</div>
              </div>
              <div className="row pb-2">
                <div className="col-4">Vehicle Name</div>
                <div className="col-4">{}</div>
              </div>
              <div className="row pb-2">
                <div className="col-4">Vehicle Registration No.</div>
                <div className="col-4">{vehicleDetails.registrationNumber}</div>
              </div>
              <div className="row pb-2">
                <div className="col-4">Vehicle Tag</div>
                <div className="col-4">{vehicleDetails.tag}</div>
              </div>
            </div>
            <div className="col-3">
              <button
                className="gray-card btn"
                onClick={() => {
                  PrintInfo();
                }}
              >
                <DownloadIcon />
                <span className="px-2 fw-bold fs-6">Download receipt</span>
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {showAddVehicle && (
        <AddVehicleComponent onClose={() => setShowAddVehicle(false)} />
      )}
      {showDeleteVehicle && (
        <ConfirmDialogComponent
          loading={loading}
          message="This action is irreversible! <br/>Are you sure you want to delete this vehicle?"
          confirm={() => {
            DeleteVehicle(selectVehicleId);
          }}
          onClose={() => {
            setShowDeleteVehicle(false);
          }}
        />
      )}
    </div>
  );
}
