/* eslint-disable no-unreachable */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './../style.css';
import { GetRequest, PostRequest } from '../../../includes/functions';
import { ActionProps, BusinessProps } from '../../../includes/types';
import { BaseLoader } from '../../../components/baseloader';
import { HistorySearchBar } from '../components/HistorySearchBar';
import { ConfirmDialogComponent } from '../components/confirmDialog';
import { EditRoleComponent, EditUserComponent } from '../components/editUser';
import { AddBusinessComponent } from '../components/addPersonnel';
import { NoBusinessIcon } from '../../../assets/icons/NoBusinessIcon';
import { EyeOpen } from '../../../assets/icons/eye';
import { Menu } from './HistoryTables/invitations';
import ThreeVerticalDotsIcon from '../../../assets/icons/threeDots';
import moment from 'moment';
import { Pagination } from '../../../components/pagination';
import { toast } from 'react-toastify';
// import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { CONSTANTS } from '../../../includes/constant';
import { DownloadCSV } from '../../../includes/createCSVFileHook';
import { BaseButton, WhiteButton } from '../../../components/buttons';
import { InviteAdminComponent } from '../components/addPersonnel/inviteAdmin';
interface ActiveListProp {
  fetching?:boolean;
  activeAdmins:RoleProps[];
  onSelectedBusiness:(data:RoleProps)=>void;
  searchText?:string;
}

const ActiveList = (props:ActiveListProp) => (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">S.No.</th>
          <th scope="col">Full name</th>
          <th scope="col">Email Address</th>
          <th scope="col">Role</th>
          {/* <th scope="col">Date Joined</th> */}
          <th scope="col">Status</th>
           <th scope="col"></th> 
        </tr>
      </thead>
      <tbody>
        {props?.fetching && (
          <tr>
            <td colSpan={10}>
              <BaseLoader /> <small className="fs-small">Fetching...</small>
            </td>
          </tr>
        )}
        {props.activeAdmins.length === 0 ? <tr>
            <td colSpan={7}>
              <div className="text-center p-5">
                <NoBusinessIcon />
                <div className="fs-6 fw-bold my-3">No active admins yet</div>
                <WhiteButton
                  onClick={() => {
                  //  props.onSelectedBusiness(true);
                  }}
                >
                  <span className="mx-2">Invite new admin</span>
                </WhiteButton>
              </div>
            </td>
          </tr>:props.activeAdmins.map((admin, index) => (
            <tr key={admin?._id}>
              <td>{(index + 1) > 9?(index + 1):"0"+(index + 1)}</td>
              <td>{admin?.firstName} {admin?.lastName}</td>
              <td>{admin.email}</td>
              <td>{admin.role}</td>
              {/* <td>{moment(admin?.createdAt).format("Do, MMM YYYY")}</td> */}
              <td><div className={`status-${String(admin.status).toLowerCase()}`}>{admin.status}</div></td>
              <td>
              <Menu
                list={[]}
                type="role"
                onValue={(d) => {
                   props.onSelectedBusiness({...admin,action:d.action});
                }}
              >
                <ThreeVerticalDotsIcon />
              </Menu>
              </td> 
            </tr>
          ))
        }
      </tbody>
    </table>
  );
interface PendingInvitesListProps {
  pendingInvites: RoleProps[];
  fetching: boolean;
  loadingItem: string;
  searchText: string;
  onSelectedBusiness: (business: RoleProps) => void;
  onCancelInvitation: (invite: RoleProps) => void;
  onResendInvitation: (invite: RoleProps) => void;
}
const PendingInvitesList = (props:PendingInvitesListProps) => (
    <div className="main-scrollable p-5 pt-0">
      <table className="table table-responsive">
        <thead>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Full name</th>
            <th scope="col">Email Address</th>
            <th scope="col">Date</th>
            <th scope="col" style={{width:180}}></th>
          </tr>
        </thead>
        <tbody>
          {props.fetching && (
            <tr>
              <td colSpan={4}>
                <BaseLoader /> <small className="fs-small">Fetching...</small>
              </td>
            </tr>
          )}
          {props.pendingInvites.length === 0 ? (
            <tr>
              <td colSpan={5}>
                <div className="text-center p-5">
                  <NoBusinessIcon />
                  <div className="fs-6 fw-bold my-3">No pending invites yet</div>
                </div>
              </td>
            </tr>
          ) : (
            props.pendingInvites.map((invite, index) => (
              <tr key={invite._id}>
                <td>{index + 1}</td>
                <td>{invite.firstName} {invite.lastName}</td>
                <td>{invite?.email}</td>
                <td>
                  <div
                    className={`status-${String(invite.status).toLowerCase().replace("invited cancelled","suspended").replace("invited","pending")}`}
                  >
                    {String(invite.status).toLowerCase().replace("invited cancelled","Cancelled").replace("invited","Invited")}
                  </div>
                </td>
                <td style={{ width: 50 }}>
                  <div
                    style={{ width: 50 }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    {props.loadingItem === invite._id ? (
                      <BaseLoader />
                    ) : (
                      !invite.status?.toLowerCase().includes("cancelled")?<Menu
                        onValue={(value) => {
                          if (value.action === 'resend') {
                            props.onResendInvitation(invite);
                          }
                          if (value.action === 'cancel') {
                            props.onSelectedBusiness({...invite,action:value.action});
                          }
                          if (value.action === 'delete') {
                            props.onSelectedBusiness({...invite,action:value.action});
                          }
                        }}
                        type="data"
                      >
                        <ThreeVerticalDotsIcon />
                      </Menu>:null
                    )}
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
export interface RoleProps {
    permissions?: RolePermissionProps[];
    status?:string;
    _id?:string;
    name?:string;
    description?:string;
    createdAt?:string;
    updatedAt?:string;
    __v?:number;
    email?:string;
    action?:ActionProps;
    firstName?:string;
    lastName?:string;
    isVerified?:boolean;
    image?:string;
    role?:string;
}
export type RolePermissionProps = {
  actions: actionsType[];
  _id?:string;
  feature?:string;
  __v?:number;
}
export type actionsType =  "view"|"create"|"delete";
const RolesAndPermissionsSection: React.FC = () => {
  const [showAddBusiness, setShowAddBusiness] = useState<boolean>(false);
  const [showInviteAdmin, setShowInviteAdmin] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [updating, setUpdating] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [showEditUser, setShowEditUser] = useState<boolean>(false);
  const [listOfRoles, setListOfRoles] = useState<RoleProps[]>([]);
  const [pendingInvites, setPendingInvites] = useState<RoleProps[]>([]);
  const [activeAdmins, setActiveAdmins] = useState<RoleProps[]>([]);
  const [startDate, setStartDate] = useState<string>(
    moment().subtract(3, 'months').toISOString()
  );
  const [endDate, setEndDate] = useState<string>(moment().toISOString());
  const [numberOfActive, setNumberOfActive] = useState<number>(0);
  const [numberOfPending, setNumberOfPending] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(50);
  const [fetching, setFetching] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('activeAdmin');
  const [selectedBusiness, setSelectedBusiness] =
    useState<BusinessProps | null>(null);
    const [editAdminRole, setEditAdminRole] =
    useState<BusinessProps | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [filterString, setFilterString] = useState<string>('');
  const [loadingItem, setLoadingItem] = useState<string>('');

  const handleExport = () => {
    DownloadCSV({ data: listOfRoles, fileName: 'roles' });
  };

  const GetRoles = (page: number) => {
    setFetching(true);
    GetRequest("admin?status=Active",
      {},
      false
    ).then((res) => {
        setFetching(false);
        if (res.status && res.data) {
        try {
          const RoleList: RoleProps[] = res.data.admins;
          setActiveAdmins(RoleList);
          setNumberOfActive(RoleList.length);
          setListOfRoles(RoleList);
        } catch(error){
          console.log(error);
        }
        }
      })
  };

  const UpdateUserRole = (values: any) => {
    return ;
    setUpdating(true);
    PostRequest('role', values, true)
      .then((response) => {
        setUpdating(false);
        if (response.status) {
          GetRoles(currentPage);
          setShowEditUser(false);
        }
      })
      .catch((error) => {
        setUpdating(false);
        console.error('Error updating user:', error);
      });
  };

  const DeleteAdmin = (id: any) => {
    setLoading(true);
    PostRequest(`delete:admin/${id}`, {}, true)
      .then((response) => {
        setLoading(false);
        if (response.status) {
          setPendingInvites(pendingInvites.filter((a,i)=>a._id !== id))
          setActiveAdmins(activeAdmins.filter((a,i)=>a._id !== id))
          setSelectedBusiness(null);
        }
      })
  };

  const CancelInvitation = (v: BusinessProps) => {
    setLoadingItem(String(v._id));
    PostRequest(`put:business/invite/cancel/${v._id}`, {}, true)
      .then((response) => {
        setLoadingItem('');
        if (response.status) {
          GetRoles(currentPage);
          setShowConfirmDialog(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error cancelling invitation', error);
      });
  };

  const ResendInvitation = (v: BusinessProps) => {
    setLoadingItem(String(v._id));
    PostRequest(
      `put:business/invite/resend`,
      {
        companyEmail: v.email,
      },
      true
    ).then((res) => {
      setLoadingItem('');
    });
  };
  useEffect(() => {
    GetRoles(currentPage);
  }, []);
  return (
    <div style={{ position: 'relative' }}>
      <div className="main-scrollable p-5 pt-0">
        <div className="heading mb-3">
          <b className="fCap me-3">Roles and permission</b>
        </div>
        <div className="d-flex">
          <HistorySearchBar
            calenderPosition={'right'}
            onSearch={(d) => {
              setFilterString(d);
            }}
            page="history"
            onAddBusiness={() => {
              setShowInviteAdmin(true);
            }}
            processing={false}
            onExportPersonnel={() => {
              handleExport();
            }}
            onImportBusiness={() => {
              // setShowImportPersonnel(true)
            }}
            showFilter={true}
            onFilterValue={({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
          />

          <BaseButton
            onClick={() => {
              setShowInviteAdmin(true);
            }}
          >
            Invite admin
          </BaseButton>
        </div>
        <nav className="nav pl-c">
        <span
          onClick={() => {
            setActiveTab("activeAdmin");
            setSearchText('');
            GetRoles(1);
          }}
          className={`nav-link active btn p-2 mb-2 ${
            activeTab === "activeAdmin" ? "fw-bold selected-tab" : "fw-normal"
            }`}
          aria-current="page">
          Active Admin <span className={activeTab === 'active' ?'tab-count-active':'tab-count-inactive'}>{numberOfActive}</span>
        </span>
        {/* <span
          onClick={() => {
            setActiveTab("pendingInvite");
            setSearchText('');
            GetRoles(1);
          }}
          className={`nav-link btn p-2 mb-2 ${
            activeTab === "pendingInvite" ? "fw-bold selected-tab" : "fw-normal"}`}>
          Pending Invite  <span className={activeTab !== 'active' ?'tab-count-active':'tab-count-inactive'}>{numberOfPending}</span> 
        </span> */}
      </nav>
        {activeTab === 'activeAdmin' ? (
          <ActiveList
            activeAdmins={activeAdmins}
            fetching={fetching}
            onSelectedBusiness={(d)=>{
              setSelectedBusiness(d);
            }}
            searchText={searchText}
          />
        ) : (
          <PendingInvitesList
            pendingInvites={pendingInvites}
            fetching={fetching}
            loadingItem={loadingItem}
            searchText={searchText}
            onSelectedBusiness={(d)=>setSelectedBusiness(d)}
            onCancelInvitation={(d)=>CancelInvitation(d)}
            onResendInvitation={(d)=>ResendInvitation(d)}
          />
        )}
        <Pagination
          onFilterRow={(d) => {
            setPageSize(d);
          }}
          onPage={(d) => {
            GetRoles(d);
            setCurrentPage(d);
          }}
        />
        {selectedBusiness?.action === "remove_admin" && (
          <ConfirmDialogComponent
            onClose={() => {
              setSelectedBusiness(null)
            }}
            loading={loading}
            title={selectedBusiness.action === "remove_admin"?`Are you sure you want to ${String(selectedBusiness?.action).replace("remove_admin","remove")} admin?`:`Are you sure you want to ${selectedBusiness?.action} this invite?`}
            data={selectedBusiness!}
            confirm={() => {
              if(selectedBusiness.action === "delete" || selectedBusiness.action === "remove_admin")
              {
                DeleteAdmin(selectedBusiness?._id);
              }
              if(selectedBusiness.action === "cancel")
                {
                  CancelInvitation(selectedBusiness);
                }
            }}
          />
        )}

        {selectedBusiness?.action === "change_role" && (
          <EditRoleComponent
            loading={updating}
            employeeData={selectedBusiness}
            onClose={() => setSelectedBusiness(null)}
            onValue={(values) => {
              UpdateUserRole(values);
            }}
          />
        )}

        {showInviteAdmin && (
          <InviteAdminComponent onClose={() => setShowInviteAdmin(false)} />
        )}
      </div>
    </div>
  );
};

export default RolesAndPermissionsSection;
