import React, { ChangeEvent, FormEvent, useState } from "react"
import BaseInput from "../../../../components/baseInput";
import { BaseButton } from "../../../../components/buttons";
import { PostRequest } from '../../../../includes/functions';
import { emitEvent } from "../../../../includes/customEventHook";
import { CONSTANTS } from "../../../../includes/constant";
import { BusinessProps, UserProps } from "../../../../includes/types";

interface AddBusinessComponentprops {
  onClose: () => void;
  data:BusinessProps;
}
export const AddEmployeeComponent = (props: AddBusinessComponentprops) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<UserProps>({
    email:"",
    firstName:"",
    lastName:""
  });
  const handleChange = (e: ChangeEvent<HTMLInputElement>)=> {
    setFormData({
      ...formData,
      [e.target.name]:e.target.value
    })
}
  return <div className="modal" tabIndex={-1} >
    <div className="modal-dialog" >
      <div className="modal-content" style={{ borderRadius: 20 }}>
        <div className="modal-header">
          <h5 className="modal-title">Invite employee</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.onClose}></button>
        </div>
        <div className="modal-body p-3">
          <form
            onSubmit={(e:FormEvent) => {
              e.preventDefault();
              setLoading(true);
              const data =  {
                email:formData.email,
                firstName:formData.firstName,
                lastName:formData.lastName,
                business:props.data._id
              }
              // return alert(JSON.stringify(data));
              PostRequest("employee/invite",data, true).then((res) => {
                setLoading(false);
                if (res.status) {
                  emitEvent(CONSTANTS.Events.reloadBusiness, {})
                  if (props.onClose) {
                    props.onClose()
                  }
                }
              })
            }}
          >
            <div className="pt-3" >
              <BaseInput
                  label="Employee First Name"
                  name='firstName'
                  type='text'
                  max={20}
                   placeholder="e.g John"
                  onValueChange={handleChange}
                  value={formData.firstName}
                  required
                />
                <BaseInput
                  label="Employee Last Name"
                  name='lastName'
                  type='text'
                  max={20}
                  placeholder="e.g Paul"
                  onValueChange={handleChange}
                  value={formData.lastName}
                  required
                />
                <BaseInput
                  label="Employee email"
                  name='email'
                  type='email'
                  max={50}
                  placeholder="Employee's email"
                  onValueChange={handleChange}
                  value={formData.email}
                  required
                />
              </div>
              <div className="row px-3">
                <BaseButton
                type="submit"
                  loading={loading}
                  style={{ minWidth: 130 }}
                >Send invitation
                </BaseButton>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
}