/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FormEvent, useEffect, useState } from 'react';
import './../style.css';
import { SearchBar } from '../components/searchBar';
import { AddBusinessComponent } from '../components/addPersonnel';
import { ImportPersonnelComponent } from '../components/importPersonnel';
import ActiveTable from './HistoryTables/activeBusiness';
import InvitationTable from './HistoryTables/invitations';
import { GetRequest, PostRequest } from '../../../includes/functions';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { GoBackIcon } from '../../../assets/icons/BackIcon';
import { CONSTANTS } from '../../../includes/constant';
import { BaseButton, LightYellowButton } from '../../../components/buttons';
import { BusinessProps, CountryProps } from '../../../includes/types';
import {
  emitEvent,
  subscribeEvent,
  unsubscribeEvent,
} from '../../../includes/customEventHook';
import { DownloadCSV } from '../../../includes/createCSVFileHook';
import BusinessDetails from './businessDetails';
import BaseInput from '../../../components/baseInput';
import BaseSelectInput from '../../../components/baseSelectInput';

export type TabProps =
  | 'Business Information'
  | 'Employee Data'
  | 'Treepz History'
  | 'Attached vehicles';

export default function BusinessesSection() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState<string>('active');
  const [showAddBusiness, setShowAddBusiness] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [showImportPersonnel, setShowImportBusiness] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [numberOfActive, setNumberOfActive] = useState<number>(0);
  const [numberOfPending, setNumberOfPending] = useState<number>(0);
  const [exportedList, setExportedList] = useState<BusinessProps[]>([]);

  const HandleExport = () => {
    const data = exportedList.map((a,i)=>{
      return {
        name:a.name,
        email:a.email,
        date:a.createdAt
      }
    });
    DownloadCSV({ data: data, fileName: 'business' });
  };

  useEffect(() => {
    subscribeEvent(CONSTANTS.Events.reloadBusiness, (data) => {
      setTab('invited');
    });
    subscribeEvent(CONSTANTS.Events.reloadAllBusiness, ({ detail }) => {
      if (Array.isArray(detail.detail)) {
        const data: BusinessProps[] = detail.detail as BusinessProps[];
        setExportedList(data);
        setNumberOfActive(
          data.filter((a: BusinessProps, i: number) => a.status !== 'Pending')
            .length
        );
        setNumberOfPending(
          data?.filter((a: BusinessProps, i: number) => a.status === 'Pending')
            .length
        );
      }
    });
    return () => {
      unsubscribeEvent(CONSTANTS.Events.reloadBusiness, () => {});
      unsubscribeEvent(CONSTANTS.Events.reloadAllBusiness, () => {});
    };
  }, [tab]);
  const businessId = searchParams.get('id')
  if (businessId) {
    return <BusinessDetails
    id={businessId} 
    />;
  }

  return (
    <div className="main-scrollable p-5 pt-0">
      <div className="heading mb-3">
        <b className="fCap">Businesses</b>
      </div>

      <SearchBar
        calenderPosition="left"
        onSearch={(d) => {
          setSearchText(d);
        }}
        processing={processing}
        page="businesses"
        onAddBusiness={() =>{
          setShowAddBusiness(true)
        }}
        onExportPersonnel={() => {
          HandleExport();
        }}
        onImportBusiness={() =>{ 
          setShowImportBusiness(true)}
        }
        showFilter={true}
        onFilterValue={({ startDate, endDate }) => {
          const sendData = { startDate, endDate };
          const reloadEvent = new CustomEvent(
            tab === 'active' ? 'reloadActiveTable' : 'reloadinvitedTable',
            { detail: sendData }
          );
          window.dispatchEvent(reloadEvent);
        }}
      />
      <nav className="nav pl-c">
        <span
          onClick={() => {
            setTab('active');
            setSearchText('');
          }}
          className={`nav-link active btn p-2 mb-2 ${
            tab === 'active' ? 'fw-bold selected-tab' : 'fw-normal'
          }`}
          aria-current="page">
          Active <span className={tab === 'active' ?'tab-count-active':'tab-count-inactive'}>{numberOfActive}</span>
        </span>
        <span
          onClick={() => {
            setSearchText('');
            setTab('invited');
          }}
          className={`nav-link btn p-2 mb-2 ${
            tab === 'invited' ? 'fw-bold selected-tab' : 'fw-normal'
          }`}
        >
          Pending Invite <span className={tab !== 'active' ?'tab-count-active':'tab-count-inactive'}>{numberOfPending}</span>
        </span>
      </nav>

      {tab === 'active' ? (
        <ActiveTable
          searchText={searchText}
          onAddNewBusiness={() => setShowAddBusiness(true)}
        />
      ) : null}

      {tab === 'invited' ? 
      <InvitationTable 
      searchText={searchText} /> : null}
      {showAddBusiness && (
        <AddBusinessComponent 
        onClose={() => 
          setShowAddBusiness(false)} />
      )}
      {showImportPersonnel && (
        <ImportPersonnelComponent
          onClose={() => setShowImportBusiness(false)}
        />
      )}
    </div>
  );
}
interface BusinessComponentProps {
  onClose: (status?:boolean) => void;
  onValue?: (v: any) => void;
  data: BusinessProps | null;
  loading?: boolean;
}

export const ViewBusinessDetailsComponent = (props: BusinessComponentProps) => {
  const [formData,setFormData] = useState<BusinessProps>(props.data!)
  const [loading,setLoading] = useState<boolean>(false)
  const listOfCountry:CountryProps[] = [
    {name:"Nigeria",
      cities:[
        "Abuja", "Kano", "Kaduna", "Maiduguri", "Sokoto", "Minna", "Jos", "Zaria", "Katsina", "Birnin Kebbi",
        "Lagos", "Ibadan", "Abeokuta", "Osogbo", "Akure", "Ilorin", "Benin City", "Oyo", "Shagamu",
        "Port Harcourt", "Calabar", "Uyo", "Warri", "Enugu", "Onitsha", "Aba", "Owerri", "Umuahia",
        "Abakaliki", "Ile-Ife", "Sapele", "Lokoja", "Makurdi", "Ifo", "Jalingo", "Gombe", "Yola", "Kogi",
        "Kano", "Bauchi", "Sokoto", "Kaduna", "Ilorin", "Akure", "Abeokuta"
    ]},
    {name:"Ghana",
      cities: [
        "Accra", "Kumasi", "Takoradi", "Tamale", "Ashaiman", "Sekondi", "Cape Coast", "Koforidua", 
        "Sunyani", "Ho", "Tema", "Mampong", "Techiman", "Akim Oda", "Tarkwa", "Obuasi", "Akosombo", 
        "Berekum", "Sefwi Wiawso", "Nkoranza", "Bolgatanga", "Wa", "Chirano", "Bawku", "Ejisu", "Nungua"
    ]},
    {name:"Uganda",
      cities:[
        "Kampala", "Entebbe", "Mbarara", "Jinja", "Mbale", "Masaka", "Fort Portal", "Lira", "Gulu", 
        "Kabale", "Arua", "Kasese", "Soroti", "Kampala", "Masindi", "Bushenyi", "Hoima", "Nakasongola", 
        "Kiryandongo", "Pader", "Kyenjojo", "Kagadi", "Iganga", "Namutumba", "Buhweju", "Bombo", 
        "Kotido", "Kaliro", "Rakai", "Kassanda", "Nakaseke"
    ]},
    {name:"Kenya",
      cities:[
        "Nairobi", "Mombasa", "Kisumu", "Nakuru", "Eldoret", "Thika", "Kisii", "Machakos", 
        "Malindi", "Nyeri", "Meru", "Embu", "Kericho", "Kitale", "Voi", "Kakamega", 
        "Bungoma", "Lamu", "Murang'a", "Nandi", "Ruiru", "Kiambu", "Homabay", "Bomet", 
        "Siaya", "Migori", "Narok", "Garissa", "Wajir", "Mandera", "Baringo", "Taveta"
    ]},
    {name:"Canada",
      cities: [
        "Toronto", "Vancouver", "Montreal", "Calgary", "Ottawa", "Edmonton", "Winnipeg", 
        "Quebec City", "Hamilton", "Kitchener", "London", "Victoria", "Halifax", "Surrey", 
        "Burnaby", "Richmond", "Markham", "Laval", "Brampton", "Oshawa", "Burlington", 
        "St. John's", "Gatineau", "Saskatoon", "Regina", "Mississauga", "Kingston", "Abbotsford", 
        "Red Deer", "Thunder Bay", "Chicoutimi", "Prince George", "Saint-Jean-sur-Richelieu", 
        "Saint John", "Moncton", "Sherbrooke", "Niagara Falls", "Fredericton", "Sudbury", 
        "Peterborough", "Kamloops", "Lethbridge", "Nanaimo", "Courtenay", "New Westminster"
  ]}
  ];
  var list = listOfCountry.filter((a,i)=>a.name === formData.country);
  var Cities:string[] = [];
  if(list.length !== 0 )
  {
    Cities = list[0].cities;
  }
  const GetBussiness = ()=>{
    GetRequest(`business/${props?.data?._id}`, {}, false).then((response) => {
      return alert(JSON.stringify(props?.data?._id))
      if (response.status) {
          setFormData({
            ...formData,
            ...response.data
          }) 
        }
      })
  }
  useEffect(()=>{
    if(props.data)
    {
    setFormData(props.data);
    } 
  },[props.data])

    const handleSubmit =(e:FormEvent) => {
      e.preventDefault();
      const data = {
      adminFirstName: formData.firstName,
      adminLastName: formData.lastName,
      city: formData.city,
      country:formData.country,
      name:formData.name
      }
      setLoading(true);
    PostRequest(`put:business/${props?.data?._id}`, data, true).then((response) => {
      setLoading(false);
        if (response.status) {
          props.onClose(true);
        }
      })
    }
  return (
    <div className="modal" tabIndex={-1}>
      <div className="modal-dialog">
        <div
          className="modal-content"
          style={{ borderRadius: 20, marginTop: 100 }}
        >
          <div className="modal-header">
            <h5 className="modal-title">{props.data?.action  === "view"?"Business details":"Edit business details"}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={()=>props.onClose()}
            ></button>
          </div>
          <div className="modal-body p-3">
            <form
            onSubmit={handleSubmit}
            >
                <div className="">
                  <BaseInput
                    label="Business name"
                    name="name"
                    type="text"
                    disabled={props.data?.action  === "view"}
                    placeholder="Great holding limited"
                    onValueChange={({target})=>{
                      setFormData({...formData,name:target.value})
                    }}
                    value={formData.name}
                    required={true}
                  />
                  <BaseInput
                    label="Company's email"
                    name="email"
                    type="email"
                    disabled={true}
                    placeholder="Company's email"
                    onValueChange={()=>{
                      return ;
                    }}
                    value={props.data?.email}
                    required={true}
                  />
                  <BaseSelectInput 
                label="Country"
                required
                placeholder="Select country"
                value={formData.country!}
                name="country"
                options={listOfCountry.map((a,i)=>{
                  return {
                    value: a.name,
                    title:a.name,
                    selected:false
                  }
                })}
                onValueChange={(d)=>{
                  setFormData({...formData,country:d?.value,city:""});
                }}
                />
                <BaseSelectInput 
                label="City"
                required
                placeholder="Select city"
                value={formData.city!}
                name="city"
                options={Cities.map((a,i)=>{
                  return {
                    title:a,
                    value:a,
                  }
                })}
                onValueChange={(d)=>{
                  setFormData({...formData,city:d?.value});
                }}
                />
             <div className="p-3 row" style={{ paddingRight: 30 }}>
                    <BaseButton 
                    type='submit'
                    loading={loading} 
                    >
                   Update business information
                    </BaseButton>
                  </div>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};